import React, { useState, useEffect } from 'react';
import { OrganizationCarouselCard } from 'components/modules/cards';
import { MultiCarousel } from 'components/modules/carousels';

import { OrganizationCarouselData } from 'components/data';

function TestimonialSection() {
  const [organization, setOrganization] = useState([]);

  useEffect(() => {
    const data = OrganizationCarouselData;
    setOrganization([...data]);
  }, []);

  return (
    <div className="bg-green py-12 md:px-40 px-6">
      <div>
        <h2 className="font-bold md:text-5xl text-4xl text-white mb-8 text-center px-2">
          Organization
        </h2>
      </div>

      <MultiCarousel>
        {organization.map((item) => {
          return (
            <OrganizationCarouselCard
              key={item.id}
              image={item.image}
              name={item.btnUrl}
              title={item.title}
              content={item.content}
            />
          );
        })}
      </MultiCarousel>
    </div>
  );
}

export default TestimonialSection;
