import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import ServicesView from './ServicesView';

export const ServicesContainer = () => {
  return (
    <PageWrapper>
      <ServicesView />
    </PageWrapper>
  );
};
