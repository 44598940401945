import { successImg } from 'assets/images';
import React from 'react';

function Success() {
  return (
    <div className="md:w-[623px] w-full md:h-[723px] h-[400px] shadown-md flex flex-col items-center justify-center md:px-8">
      <img src={successImg} />
      <p className="text-lg pt-4 text-main text-center">
        We recieved your message and we will get back to you as soon as possible!
      </p>
    </div>
  );
}

export default Success;
