import { services_hero_bg } from 'assets/images';
import ServicesHeroCard from 'components/modules/cards/ServicesHeroCard';
import React from 'react';

function OurServicesHeroSection() {
  return (
    <ServicesHeroCard
      image={services_hero_bg}
      custom_styles={'bg-opacity-90'}
      title={'Our Services'}
      subTitle={'Lists of Services and Partnership with Us'}
    />
  );
}

export default OurServicesHeroSection;
