import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useTailwindSquareSizing } from 'hooks';

const { sizes, getSize } = useTailwindSquareSizing();

const DarkArrowRight = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${getSize(size)}`}
    >
      <path d="M5 12h13M12 5l7 7-7 7" />
    </svg>
  );
};
DarkArrowRight.propTypes = {
  size: PropTypes.oneOf(sizes)
};

export default memo(DarkArrowRight);
