import React from 'react';
import { mediaimg } from 'assets/images';

function HeroSection() {
  return (
    <div className="w-full" style={{ background: `url(${mediaimg})` }}>
      <div className="flex flex-col w-full h-96 items-center justify-center">
        <h1 className="text-gold text-6xl font-extrabold text-center mb-4">PRESS ROOM</h1>
        <p className="text-2xl text-white font-medium text-center">Featured News.</p>
      </div>
    </div>
  );
}

export default HeroSection;
