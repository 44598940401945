import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { VideoCard } from 'components/modules/cards';

const API_KEY = 'AIzaSyA3R250GDeKt5TVfAG91xFQt0R06bTFRws';
const CHANNEL_ID = 'Stonm3Fy5-c0TVFT_T6BQ';

async function getVideos() {
  const response = await axios.get(
    `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`
  );
  console.log(response.data);
  return response.data.items;
}

const CardSection = () => {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getVideos();
        setVideos(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const getViewCount = async (videoId) => {
    const response = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=statistics&id=${videoId}&key=${API_KEY}`
    );
    return response.data.items[0].statistics.viewCount;
  };

  return (
    <div className="flex h-[800px]">
      <p className="text-4xl text-main md:mt-12 mt-4 font-semibold">Videos</p>
      {videos.map((video) => (
        <VideoCard
          key={video.id.videoId}
          title={video.snippet.title}
          description={video.snippet.description}
          viewers={getViewCount(video.id.videoId).then((viewCount) => viewCount)}
          date={video.snippet.publishedAt}
          image={`https://img.youtube.com/vi/${video.id.videoId}/maxresdefault.jpg`}
        />
      ))}
    </div>
  );
};

export default CardSection;
