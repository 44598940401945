import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { Field } from 'formik';

function SelectFormGroup({
  nameAndId,
  placeholder,
  label,
  disabled,
  children,
  className,
  labelClassName
}) {
  return (
    <div className={`form-control ${className || 'w-full my-1'}`}>
      <label className={`label ${labelClassName}`} htmlFor={nameAndId}>
        <span className="label-text text-main">{label}</span>
      </label>
      <div>
        <Field
          name={nameAndId}
          id={nameAndId}
          as={'select'}
          placeholder={placeholder}
          className="bg-theme border-theme rounded-md w-full text-main "
          disabled={disabled}
        >
          {children}
        </Field>
        <ErrorMessage
          component="label"
          name={nameAndId}
          className="label-text-alt w-full text-alerts-error-color"
        />
      </div>
    </div>
  );
}
SelectFormGroup.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.arrayOf(PropTypes.element),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool
};

export default SelectFormGroup;
