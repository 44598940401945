import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PdfDisplay } from 'components/modules/displays';

function PdfCard({ image, title, content, pdfFile }) {
  return (
    <Link to={PdfDisplay}>
      <div className="bg-[#DBF5E8] md:w-96 w-full md:h-96 h-80 p-8 rounded-md shadow-md flex flex-col gap-4">
        <div>
          <img src={image} className="md:h-16 h-12 md:w-16 w-12" />
        </div>
        <p className="md:text-3xl text-2xl font-medium text-main md:mb-2 mb-1">{title}</p>
        <p className="text-main font-normal text-base">{content}</p>
        <a href={pdfFile} download className="text-gold text-2xl font-normal md:mt-4 mt-2">
          Download PDF
        </a>
      </div>
    </Link>
  );
}
PdfCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  pdfFile: PropTypes.string
};
export default PdfCard;
