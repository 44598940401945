import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { calender, clock } from 'assets/icons';
import { Button } from 'components/widgets/buttons';

function FullStorySection() {
  const [viewAllNews, setViewAllNews] = useState(false);
  const handleNewsClick = () => {
    console.log('all news clicked');
    setViewAllNews(!viewAllNews);
  };
  return (
    <div className="md:px-40 px-8 pb-12">
      <div className="flex items-center justify-between gap-2">
        <div>
          <h2 className="font-bold md:text-3xl text-2xl text-main mb-12">Full Story</h2>
        </div>
        <div className="border-t-2 border-ash h-8 md:w-3/4 w-1/2"></div>
        <button onClick={handleNewsClick} className="text-gold md:text-base text-sm font-semibold">
          {viewAllNews ? 'Hide all' : 'See All'}
        </button>
      </div>
      <div className="grid md:grid-cols-10 grid-cols-1 md:gap-4 gap-8">
        <div className="col-start-1 col-span-4">
          <div className="flex flex-row md:gap-8 gap-6 md:mb-0 mb-2">
            <p className="text-main md:text-base text-xs md:font-normal font-light">
              By James Landale
            </p>
            <p className="text-ash  md:text-base text-xs flex items-center md:gap-2 gap-1">
              <span>
                <img src={calender} />
              </span>
              21 NOV 2022
            </p>
            <p className="text-green md:text-base text-xs flex items-center md:gap-2 gap-1">
              <span>
                <img src={clock} />
              </span>
              10 MIN READ
            </p>
          </div>
          <p className="text-ash text-base font-medium pb-2">Diplomatic Correspodent</p>
          <h2 className="text-main md:font-bold font-medium md:text-4xl text-2xl pb-2 pt-2">
            Ukraine War: Five ways Russia’s invasion may play out.
          </h2>
          <p className="text-ash text-base font-medium">
            Wars ebb and flow. Vladimir Putin’s invasion of Ukraine is no exception. Early fears of
            a swift conquest were succeeded by Russian retreat and Ukrinian resistance. That has now
            been met by a more focused Russian offensive in the eat.....
            <span className="text-gold">
              <Button>Read More</Button>
            </span>
          </p>
        </div>
        <div className="md:col-start-5 md:col-span-6 col-span-full bg-ash h-80 rounded-md"></div>
      </div>
      <p className="text-base font-medium text-ash md:w-[658px] w-full pt-2 sm:ml-[510px]">
        Wars ebb and flow. Vladimir Putin’s invasion of Ukraine is no exception. Early fears of a
        swift conquest
      </p>
    </div>
  );
}
FullStorySection.propTypes = {
  viewAllNews: PropTypes.bool
};
export default FullStorySection;
