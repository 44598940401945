import { ABM, ABS, AHM2, BMHM, BWC, GPANLOGO, PALCLOGO, USAF } from 'assets/images';

const data = [
  {
    id: 1,
    image: USAF,
    title: 'United States of Africa',
    content:
      'is an Initiative working towards unification of all people of African descent under one political kingdom based in Africa.',
    btnUrl: 'https://www.usafrikagov.com'
  },
  {
    id: 2,
    image: GPANLOGO,
    title: 'Global Pan Africanism Network',
    content:
      ' is an International Human Rights and Pan African organization to reunite all people of African descent, advocate for their rights and freedoms throughout the world.',
    btnUrl: 'https://www.gpanafrica.org'
  },
  {
    id: 3,
    image: BMHM,
    title: 'Black Mental Health Matters Inc',
    content:
      'vision is to promote mental health research, champion for mental health rights for people of African descent, combat medical racism...',
    btnUrl: 'https://www.bmhm.org'
  },
  {
    id: 4,
    image: BWC,
    title: 'Black Woman Can',
    content:
      'series to uplift Black Women around the world through showcasing the achievements of Black Women throughout History, capturing their daily struggles for Justice and Equality.',
    btnUrl: 'https://www.blackwomancan.com'
  },
  {
    id: 5,
    image: PALCLOGO,
    title: 'Pan African Leadership Center',
    content:
      ' nurtures young leaders, encourage women leadership and provide leadership training driven by Pan Africanism Principles to aspiring leaders of African Descent.',
    btnUrl: 'https://www.panafricanleadershipcenter.org'
  },
  {
    id: 6,
    image: AHM2,
    title: 'African History Month',
    content:
      'will encourage and promote further research, celebrate achievements all people of African descent including where Black History Month is not celebrated.',
    btnUrl: 'https://www.ahm.africa'
  },
  {
    id: 7,
    image: ABM,
    title: 'African Business Month',
    content:
      'was established to focus on promoting African businesses in Africa, Europe, Caribbean, Pacific and round the world.',
    btnUrl: 'https://www.africanbusinessmonth.org'
  },
  {
    id: 8,
    image: ABS,
    title: 'African Business Show',
    content:
      'objective is to provide a platform for people of African descent to showcase their products and services, network, advertise and grow their businesses.',
    btnUrl: ''
  }
];
export default data;
