import { donate_img } from 'assets/images';
import React from 'react';

function ServicesDonateCard() {
  return (
    <div className="relative ">
      <figure>
        <img src={donate_img} className="w-full object-cover h-[111px] md:h-96 " />
      </figure>
      <div className="bg-black absolute top-0 bg-opacity-75 w-full h-[111px] md:h-full flex flex-col items-center md:gap-5 lg:11 justify-center  md:px-20 lg:px-40 px-8">
        <div className="w-full ">
          <p className="font-normal text-base md:font-semibold md:text-5xl text-white w-full pt-9">
            Our mission is to empower every <br />
            African with the freedom to move!
          </p>
          <a
            href="/donation"
            className="md:text-4xl text-lg text-gold font-bold underline underline-offset-8"
          >
            Donate Now
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServicesDonateCard;
