import React, { useContext } from 'react';
import { lock } from 'assets/icons';
import { PartnerContext } from './Context';

function Stepper() {
  const { activeStepIndex } = useContext(PartnerContext);
  const steps = ['Companys Information', 'Partner With us', 'Personal Information'];

  return (
    <div className="md:-mt-[112px] mt-8">
      <div className="bg-gold md:h-28 h-14 w-full rounded-t-lg text-white md:px-8 px-4 flex items-center md:text-3xl text-base font-normal gap-8">
        <div className="md:w-[350px] w-full">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`stepper-text ${index === activeStepIndex ? 'block' : 'hidden'}`}
            >
              {step}
            </div>
          ))}
        </div>

        <img src={lock} className="md:h-6 h-4" />
        <div className="flex md:gap-3 gap-2">
          {steps.map((_, index) => (
            <div
              key={index}
              className={`stepper-item md:h-4 h-2 md:w-4 w-2 ${
                index === activeStepIndex
                  ? 'bg-ash rounded-full md:h-4 h-2 md:w-8 w-3'
                  : 'bg-main rounded-full md:h-4 h-2 md:w-4 w-2'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Stepper;
