import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function PaymentOptionButton({ children, isActive, onClick, to }) {
  const activeButtonClassname =
    'after:absolute after:w-14 after:h-1 after:bg-primary-100 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:rounded-full';
  return (
    <Link
      type="button"
      className={`cursor-pointer border-2 flex gap-2 items-center border-gold md:px-7 sm:px-4 px-2 md:py-4 py-4 hover:bg-gold hover:text-white text-main md:text-xl text-xs font-normal ${
        isActive && activeButtonClassname
      }`}
      onClick={onClick}
      to={to}
    >
      {children}
    </Link>
  );
}

PaymentOptionButton.propTypes = {
  children: PropTypes.string,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

export default PaymentOptionButton;
