export default function amountSchema(values) {
  const errors = {};
  if (!values.amount) {
    errors.amount = 'Please enter an amount';
  } else if (values.fullName.length < 0) {
    errors.amount = 'Invalid Amount';
  }

  return errors;
}
