import React from 'react';

const WomenRights = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold sm:font-bold ">Women Rights</h5>
      <p className="text-base font-normal sm:text-2xl text-justify  py-3">
        He has been a strong advocate for women’s rights and specifically his work revolves around
        creating safe spaces for Black Women to share their experiences, network and tell their
        stories from an eye bird’s view.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        Daniel founded{' '}
        <span className="text-gold">Black Woman Can in August 2021 to uplift Black Women </span>
        around the world and celebrate their achievements throughout history.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        This has become a phenomenal international movement which has inspired Black Women to get
        out of their comfort zones ,{' '}
        <span className="text-gold">
          unlock their unexploited potentials and achieve their dreams
        </span>
        .
      </p>
    </div>
  );
};

export default WomenRights;
