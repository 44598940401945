// import React, { useRef, useEffect } from 'react';
// import * as google from '@google/maps';
// import { africaMap } from 'assets/images';

// const MapSection = () => {
//   const mapContainer = useRef(null);

//   useEffect(() => {
//     // Initialize the client with your API key
//     const client = new google.Client({
//       key: 'YOUR_API_KEY'
//     });

//     // Initialize the map
//     client.maps.create(
//       {
//         element: mapContainer.current,
//         center: { lat: 4.0, lng: 21.0 }, // Middle of Africa
//         zoom: 10
//       },
//       (map) => {
//         // Add a click listener to the map
//         google.maps.event.addListener(map, 'click', (event) => {
//           // Get the lat and lng of the click event
//           const lat = event.latLng.lat();
//           const lng = event.latLng.lng();

//           // Reverse geocode the lat and lng to get the address or place name
//           const geocoder = new google.maps.Geocoder();
//           geocoder.geocode({ location: { lat, lng } }, (results, status) => {
//             if (status === 'OK') {
//               if (results[0]) {
//                 // Find the country in the address components
//                 const country = results[0].address_components.find((component) => {
//                   return component.types.includes('country');
//                 });

//                 if (country) {
//                   // Get the country name and population
//                   const countryName = country.long_name;
//                   const countryPopulation = country.population;

//                   // Show the country name and population
//                   alert(`Country: ${countryName}\nPopulation: ${countryPopulation}`);
//                 }
//               }
//             }
//           });
//         });
//       }
//     );
//   }, []); // Only run this effect once
//   return (
//     <div ref={mapContainer} className="w-full">
//       <img src={africaMap} alt="map" />
//     </div>
//   );
// };

// export default MapSection;

import { africaMap } from 'assets/images';
import React from 'react';

const MapSection = () => {
  return (
    <div>
      <img src={africaMap} alt="map" />
    </div>
  );
};

export default MapSection;
