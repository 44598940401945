import { calender, clock, whiteEye } from 'assets/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function NewsCard({ date, views, readTime, content, newsLink }) {
  return (
    <Link to={newsLink}>
      <div className="h-80 md:w-80 w-full rounded-lg shadow-md flex flex-col">
        <div className="bg-ash h-40 w-full rounded-t-md"></div>
        <div className="relative flex justify-end">
          <div className="flex gap-2 items-center justify-center bg-green text-white w-20 h-9 -mt-36 p-2 mr-4">
            <span>
              <img src={whiteEye} />
            </span>
            <p className="md:text-base text-xs">{views}</p>
          </div>
        </div>
        <div className="bg-gold h-40 w-full rounded-b-md">
          <div className="relative px-4 -mt-6">
            <div className="bg-white h-40 md:w-72 w-full px-3 py-6 text-center">
              <div className="flex justify-between pb-6">
                <p className="text-base text-ash flex gap-2 items-center">
                  <span>
                    <img src={calender} className=" h-4" />
                  </span>
                  {date}
                </p>
                <p className="text-base text-green flex gap-2 items-center">
                  <span>
                    <img src={clock} />
                  </span>
                  {readTime}
                </p>
              </div>
              <div>
                <p className="text-base font-normal text-main text-center">{content}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
NewsCard.propTypes = {
  date: PropTypes.string,
  views: PropTypes.string,
  readTime: PropTypes.string,
  content: PropTypes.string,
  newsLink: PropTypes.string
};

export default NewsCard;
