import { HeroSection, PrivacySection } from 'components/layout/Privacy';
import React from 'react';

const PrivacyView = () => {
  return (
    <div>
      <HeroSection />
      <PrivacySection />
    </div>
  );
};

export default PrivacyView;
