import { subIMG } from 'assets/images';
import React from 'react';

function SubImgSection() {
  return (
    <div className=" w-full bg-main" style={{ background: `url(${subIMG})` }}>
      <div className=" md:pl-40 px-8 md:text-left text-center flex flex-col w-full h-96  justify-center">
        <h1 className="text-white md:text-5xl text-4xl font-semibold mb-4">
          Our mission is to empower every <br />
          African with the freedom to move!
        </h1>
        <a
          href="/donation"
          className="md:text-4xl text-3xl text-gold font-bold underline underline-offset-8 hover:text-green"
        >
          Donate Now
        </a>
      </div>
    </div>
  );
}

export default SubImgSection;
