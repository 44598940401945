import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import TermsView from './TermsView';

export const TermsContainer = () => {
  return (
    <PageWrapper>
      <TermsView />
    </PageWrapper>
  );
};
