import { HeroSection, CardSection, SubImgSection, VideosSection } from 'components/layout/Press';
import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';

function PressView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection />
      </section>
      <section>
        <CardSection />
      </section>
      <section>
        <SubImgSection />
      </section>
      <section>
        <VideosSection />
      </section>
    </PageWrapper>
  );
}

export default PressView;
