import React from 'react';
import PropTypes from 'prop-types';

function SocialMediaButton({ imageUrl, href, iconClassName }) {
  return (
    <a href={href} target="_blank" className={iconClassName} rel="noreferrer">
      <img src={imageUrl} />
    </a>
  );
}

SocialMediaButton.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  href: PropTypes.string,
  iconClassName: PropTypes.string
};

export default SocialMediaButton;
