import React, { useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { PartnerContext } from './Context';
import { arrowLeft, arrowRight } from 'assets/icons';

function PartnerForm() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(PartnerContext);
  const formikInitialValues = {
    offerDonate: false,
    boardMember: false,
    partnerships: false,
    schoolCause: false,
    technologyTest: false,
    donate: false,
    expertInvitation: false,
    serviceOffering: false,
    unknown: false
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };
  return (
    <div className="md:w-[600px] h-full w-full shadow-lg md:p-8 p-3 text-main">
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize={true}>
        <Form>
          <div className="flex flex-col gap-4">
            <p className="font-bold text-base md:text-2xl py-4">
              How do you want to partner with us?
            </p>

            <label className="flex items-center cursor-pointer gap-4">
              <input name="offerDonate" type="checkbox" className="accent-gold" />
              <span className="font-normal text-base md:text-2xl text-main">
                Offer donated media/run a PSA
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field id="boardMember" name="boardMember" type="checkbox" className="accent-gold" />
              <span className="font-normal text-base md:text-2xl text-main">
                Become a Board Member or Advisor
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field
                id="partnerships"
                name="partnerships"
                type="checkbox"
                className="accent-gold"
              />
              <span className="font-normal text-base md:text-2xl text-main">
                Collaborate on a content partnership
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field id="schoolCause" name="schoolCause" type="checkbox" className="accent-gold" />
              <span className="font-normal text-base md:text-2xl text-main">
                Bring a cause into a school or classroom
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field
                id="technologyTest"
                name="technologyTest"
                type="checkbox"
                className="accent-gold"
              />
              <span className="font-normal text-base md:text-2xl text-main">
                Test a new technology or produce a case study
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field id="donate" name="donate" type="checkbox" className="accent-gold" />
              <span className="font-normal text-base md:text-2xl text-main">Donate funding</span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field
                id="expertInvitation"
                name="expertInvitation"
                type="checkbox"
                className="accent-gold"
              />
              <span className="font-normal text-base md:text-2xl text-main">
                Invite an Ad Council expert to speak
              </span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field
                id="serviceOffering"
                name="serviceOffering"
                type="checkbox"
                className="accent-gold"
              />
              <span className="font-normal text-base md:text-2xl text-main">Offer a service</span>
            </label>

            <label className="flex items-center cursor-pointer gap-4">
              <Field id="unknown" name="unknown" type="checkbox" className="accent-gold" />
              <span className="font-normal text-base md:text-2xl text-main">I don’t know yet</span>
            </label>
          </div>

          <div className="md:pt-20 py-8 flex gap-4">
            <button
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              className="flex items-center justify-center md:gap-2 gap-1 bg-white text-main border-2 border-main hover:border-gold rounded-md md:px-4 px-2 md:py-2 w-[30%] hover:bg-gold hover:text-white"
            >
              <img src={arrowLeft} />
              Back
            </button>
            <button
              type="submit"
              className="bg-gold px-8 md:py-2 flex items-center md:gap-2 gap-1 justify-center text-white rounded-md border-2 border-gold hover:border-main w-[70%] hover:bg-main"
            >
              Next <img src={arrowRight} className="md:h-6 h-4 md:w-6 w-4" />
            </button>
          </div>
          <p className="text-gold underline underline-offset-4 font-normal md:text-xl text-xs text-center md:mt-28 mt-4">
            Powered by Daniel
          </p>
        </Form>
      </Formik>
    </div>
  );
}

export default PartnerForm;
