import React from 'react';
import { background, daniel, danielSm } from 'assets/images';
import { LongGoldButton } from 'components/widgets/buttons';
import { CONNECT } from 'navigation/CONSTANTS';

function HeroSection() {
  const backgroundImgStyle = {
    backgroundImage: `url('${background}')`,
    backgroundSize: 'cover'
  };

  return (
    <div
      className="grid md:grid-cols-2 grid-cols-1 bg-main bg-opacity-90 w-full pt-12 md:px-40 px-8 "
      style={backgroundImgStyle}
    >
      <div className="flex flex-col">
        <p className="font-bold md:text-4xl text-xl text-gold pb-4 md:tracking-wider tracking-normal md:text-left text-center">
          Africans United Beyond Borders
        </p>
        <p className="font-extrabold md:text-6xl text-4xl text-center md:text-left text-white pb-4 md:tracking-wide tracking-normal">
          DANIEL<br></br>MWAMBONU
        </p>
        <p className="font-normal md:text-xl text-base text-white md:pb-8 pb-4 md:tracking-wide tracking-wide md:pl-0 px-4 md:text-left text-center">
          Africa has immense potential and for that reason it has become a raw material producer for
          Western nations. Its about time we Africans prioritize our own development in the spirit
          of Africa for Africans at home and Abroad. Africa Must Unite!
        </p>

        <LongGoldButton to={CONNECT}>Connect with Daniel</LongGoldButton>
      </div>
      <div className="">
        <img src={danielSm} className=" h-full object-cover pt-10  sm:hidden" alt="Daniel" />
        <img src={daniel} className=" h-full object-cover hidden sm:block" alt="Daniel" />
      </div>
    </div>
  );
}

export default HeroSection;
