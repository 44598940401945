import { PageWrapper } from 'components/modules/wrapper';
import { HeroSection, InfoSection, LiveSection } from 'components/layout/Events';

import React from 'react';
import EventsSection from 'components/layout/Events/EventsSection';

function EventsView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection headingText="Live" />
      </section>
      <section>
        <LiveSection />
      </section>
      <section>
        <InfoSection />
      </section>
      <EventsSection />
    </PageWrapper>
  );
}

export default EventsView;
