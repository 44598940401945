const data = [
  {
    id: 1,
    content: 'Stand for the truth no Fear no matter what for the truth is bold and gold.',
    author: '– Daniel Mwambonu'
  },
  {
    id: 2,
    content:
      'Man empowered with full knowledge of himself is a superb creature that no chains can bind.',
    author: '– Daniel Mwambonu'
  },
  {
    id: 3,
    content: 'Nothing great was ever easy ',
    author: '— Daniel Mwambonu'
  }
];
export default data;
