import React from 'react';
import { background, daniel, danielSm, organizations } from 'assets/images';
import {
  HeroSection,
  HumanRights,
  MissionSection,
  OfficialHolidays,
  OrganizationSection,
  PanAfricanism,
  RacialJustice,
  SecuriryDefense,
  Technology,
  UnitedAfrica,
  VissionSection,
  WomenRights
} from 'components/layout/Biography';

function BiographyView() {
  return (
    <div className="font-roboto text-main ">
      <HeroSection daniel={daniel} danielSm={danielSm} background={background} />

      <OrganizationSection organizations={organizations} />
      <MissionSection>
        <WomenRights />
        <HumanRights />
        <RacialJustice />
        <PanAfricanism />
        <Technology />
      </MissionSection>

      <VissionSection>
        <UnitedAfrica />
        <OfficialHolidays />
        <SecuriryDefense />
      </VissionSection>
    </div>
  );
}

export default BiographyView;
