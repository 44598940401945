import React, { useState, useEffect } from 'react';
import { NewsCard } from 'components/modules/cards';
import PropTypes from 'prop-types';
import { NewsData } from 'components/data';

function BottomCardSection({ viewAllNews }) {
  const [newsData, setNewsData] = useState([]);
  useEffect(() => {
    const data = NewsData;
    setNewsData(data);
  }, []);

  const allNews = newsData.map((news) => {
    return (
      <NewsCard
        key={news.id}
        date={news.date}
        views={news.views}
        readTime={news.readTime}
        content={news.content}
        newsLink={news.newsLink}
      />
    );
  });

  return (
    <div className="pt-4 pb-24">
      <div className="grid md:grid-cols-3 grid-cols-1 md:gap-12 gap-8 md:px-40 px-8">
        {viewAllNews
          ? allNews
          : newsData
              .sort(() => Math.random() - Math.random())
              .slice(0, 3)
              .map((news) => {
                return (
                  <NewsCard
                    key={news.id}
                    date={news.date}
                    views={news.views}
                    readTime={news.readTime}
                    content={news.content}
                    newsLink={news.newsLink}
                  />
                );
              })}
      </div>
    </div>
  );
}
BottomCardSection.propTypes = {
  viewAllNews: PropTypes.bool
};

export default BottomCardSection;
