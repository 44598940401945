import React from 'react';
import { Button } from 'components/widgets/buttons';
import { calender, clock } from 'assets/icons';

function FeaturedNewsSection() {
  return (
    <div className="md:px-40 px-8 pt-12">
      <h1 className="md:text-6xl text-4xl font-semibold md:my-12 mb-4 text-main md:text-left text-center">
        Featured News
      </h1>

      <div className="flex flex-col md:flex-row md:gap-20 gap-12">
        <div className="md:w-[717px] w-full md:h-[403px] h-[300px] bg-ash rounded-lg py-12 shadow-sm">
          <div className="md:space-y-24 space-y-16">
            <div className="flex items-end justify-end">
              <div className="h-11 w-60 bg-green flex items-center justify-center">
                <p className="text-2xl font-medium text-white ">Featured</p>
              </div>
            </div>
            <div className="relative flex items-end justify-end">
              <div className="bg-white md:px-12 px-4 py-4 md:w-96 w-72 md:h-44 h-32 ">
                <div className="flex justify-between md:mb-8 mb-4">
                  <p className="flex gap-2">
                    <img src={calender} />
                    21 NOV 2022
                  </p>
                  <p className="text-green flex gap-2">
                    <img src={clock} />
                    10 MIN READ
                  </p>
                </div>
                <p className="md:text-2xl text-lg font-normal text-main">
                  Top Four Reason to Switch to a Cloud Based HRMS
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" md:w-[410px] w-full h-[403px] bg-ash md:px-12 px-4 md:py-20 py-12 rounded-lg shadow-sm">
          <div className="text-main mb-12 md:mx-auto w-full">
            <p className="text-main text-4xl mb-2">Subscribe &</p>
            <p className="text-main text-4xl font-bold">Stay up to date on our Events!</p>
          </div>
          <div className="flex md:m-auto w-full bg-white rounded items-center justify-between p-1">
            <input
              type="email"
              placeholder="Enter email address"
              className="border-0 w-full focus:ring-0 border-none"
            />
            <div className="">
              <Button className="btn px-4 rounded-sm flex bg-main text-white items-center">
                continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedNewsSection;
