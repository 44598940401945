import React, { useContext } from 'react';
import { PartnerContext } from './Context';
import Success from './Success';
import ContactForm from './ContactForm';
import InformationForm from './InformationForm';
import PartnerForm from './PartnerForm';

function Step() {
  const { activeStepIndex } = useContext(PartnerContext);
  switch (activeStepIndex) {
    case 0:
      return <ContactForm />;
    case 1:
      return <PartnerForm />;
    case 2:
      return <InformationForm />;
    case 3:
      return <Success />;
    default:
      break;
  }
}

export default Step;
