import React from 'react';
import { cta } from 'assets/images';
import { Button, LongDarkButton } from 'components/widgets/buttons';
import { SERVICES } from 'navigation/CONSTANTS';

function ServicesSection() {
  return (
    <div className="bg-theme grid md:grid-cols-2 grid-cols-1 gap-8 py-12 md:px-40 px-8">
      <div className=" flex flex-col md:mx-0 mx-auto items-center md:items-start  my-auto">
        <p className="md:text-4xl text-3xl font-extrabold text-main md:text-left text-center mb-4">
          Want you to boost your business growth? Solution is here.
        </p>
        <p className="text-2xl text-main opacity-75 font-medium md:text-left text-center mb-6 md:mr-12">
          We provide various services to make your business grow and get bigger. Your satisfaction
          is our first priority.
        </p>
        <LongDarkButton to={SERVICES.SERVICES}>View All Services</LongDarkButton>
        <div className="mt-8 hidden md:block">
          <img src={cta} />
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-8">
        <div className="bg-white rounded text-main p-4 md:text-left text-center shadow-md">
          <div className="">
            <p className="md:text-2xl text-4xl md:font-bold font-extrabold bg-theme h-9 w-9 rounded py-1 px-1 mb-6 md:mx-0 mx-auto">
              01
            </p>
          </div>
          <p className="md:text-xl text-3xl font-bold pb-4">Hr Consulting</p>
          <p className="md:text-sm text-2xl pb-6 md:px-1 px-6">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officiat.
          </p>
          <div className="">
            <Button
              to={SERVICES.SERVICES}
              className="btn border-2 bg-white text-black w-full hover:bg-gold hover:text-white hover:border-gold"
            >
              See More
            </Button>
          </div>
        </div>

        <div className="bg-white rounded text-main p-4 md:text-left text-center shadow-md">
          <p className="md:text-2xl text-4xl md:font-bold font-extrabold bg-theme h-9 w-9 rounded py-1 px-1 mb-6 md:mx-0 mx-auto">
            02
          </p>
          <p className="md:text-xl text-3xl font-bold pb-4">Branding</p>
          <p className="md:text-sm text-2xl pb-6 md:mx-1 mx-6">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officiat.
          </p>
          <div className="">
            <Button
              to={SERVICES.SERVICES}
              className="btn border-2 bg-white text-black w-full hover:bg-gold hover:text-white hover:border-gold"
            >
              See More
            </Button>
          </div>
        </div>

        <div className="bg-white rounded text-main p-4 sm:text-left text-center shadow-md">
          <p className="md:text-2xl text-4xl md:font-bold font-extrabold bg-theme h-9 w-9 rounded py-1 px-1 mb-6 md:mx-0 mx-auto">
            03
          </p>
          <p className="md:text-xl text-3xl font-bold mb-4">Product Design</p>
          <p className="md:text-sm text-2xl mb-6 md:mx-1 mx-6">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officiat.
          </p>
          <div className="">
            <Button
              to={SERVICES.SERVICES}
              className="btn border-2 bg-white text-black w-full hover:bg-gold hover:text-white hover:border-gold"
            >
              See More
            </Button>
          </div>
        </div>

        <div className="bg-white rounded text-main p-4 md:text-left text-center shadow-md">
          <p className="md:text-2xl text-4xl md:font-bold font-extrabold bg-theme h-9 w-9 rounded py-1 px-1 mb-6 md:mx-0 mx-auto">
            04
          </p>
          <p className="md:text-xl lg:text-lg text-2xl font-bold mb-4">Software Development</p>
          <p className="md:text-sm text-2xl mb-6 md:mx-1 mx-6">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officiat.
          </p>
          <div className="">
            <Button
              to={SERVICES.SERVICES}
              className="btn border-2 bg-white text-black w-full hover:bg-gold hover:text-white hover:border-gold"
            >
              See More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesSection;
