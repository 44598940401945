import React from 'react';
import { logo, whitemsg } from 'assets/images';
import { SocialMediaButton, Button, WhatsappButton } from 'components/widgets/buttons';
import { twitterDark, youtubeDark, facebookDark, linkedinDark, instagramDark } from 'assets/icons';
import {
  BIOGRAPHY,
  EVENTS,
  MEDIA,
  CONNECT,
  DONATION,
  HOME,
  TERMS,
  PRIVACY
} from 'navigation/CONSTANTS';
import { Link } from 'react-router-dom';

function BaseFooterSection() {
  const socialIcons = [
    { id: 1, imageUrl: twitterDark, path: 'https://twitter.com/DMwambonu' },
    {
      id: 2,
      imageUrl: youtubeDark,
      path: 'https://www.youtube.com/channel/UCjFdVEbi9lwnCf93yCvgqEA'
    },
    { id: 3, imageUrl: facebookDark, path: 'https://www.facebook.com/KingMenelikIII' },
    {
      id: 4,
      imageUrl: linkedinDark,
      path: 'https://www.linkedin.com/in/daniel-mwambonu-king-menelik-iii91b185172/'
    },
    { id: 5, imageUrl: instagramDark, path: 'https://instagram.com/danielmwambonu' }
  ];
  return (
    <div className="bg-main  text-white">
      <div className="grid md:grid-cols-3 grid-rows-1 md:gap-52 gap-2 text-base py-12 md:px-40 px-8">
        <div className=" md:text-left text-center">
          <Link to={HOME}>
            <img className="mb-12 md:text-left mx-auto md:mx-0" src={logo} />
          </Link>
          <p>Changing and uniting Black people</p>
          <p>one step at a time</p>
          <div className="flex flex-col mt-8 md:justify-start justify-center gap-2">
            <Button className="hover:text-gold" to={TERMS}>
              Terms & Conditions
            </Button>
            <Button className="hover:text-gold" to={PRIVACY}>
              Privacy Policy
            </Button>
          </div>
        </div>
        <div>
          <div className="md:mx-auto mt-12 md:mt-0">
            <h4 className="mb-4 font-bold md:text-left text-center">Quick Links</h4>
            <div className="flex justify-center md:justify-start gap-12">
              <div className="flex flex-col gap-2 mr-8 text-white">
                <Button className="hover:text-gold" to="/">
                  Home
                </Button>
                <Button className="hover:text-gold" to={BIOGRAPHY}>
                  Biography
                </Button>
                <Button className="hover:text-gold" to={MEDIA.PRESS}>
                  Press
                </Button>
                <Button className="hover:text-gold" to={MEDIA.ORGANIZATION}>
                  Campaigns
                </Button>
              </div>
              <div className="flex flex-col gap-2 text-white">
                <Button className="hover:text-gold" to={MEDIA.ORGANIZATION}>
                  Organization
                </Button>
                <Button className="hover:text-gold" to={EVENTS.LIVE}>
                  Live
                </Button>
                <Button className="hover:text-gold" to={CONNECT}>
                  Connect
                </Button>
                <Button className="hover:text-gold" to={DONATION}>
                  Donate
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-center md:text-end mt-12 sm:mt-0 right-24">
          <p className="mb-4 font-bold">Contact us</p>
          <p className="">Want to talk to us?</p>
          <p className="flex gap-2 md:mx-0 mx-auto items-center md:justify-end">
            <span>
              <WhatsappButton iconClassName="pr-1" />
            </span>
            <a href="https://wa.me/254700614290" className="hover:text-gold">
              +254 70-0614290
            </a>
          </p>
          <a
            href="mailto:info@danielmwambonu.com"
            className="flex gap-2 md:mx-0 mx-auto items-center md:justify-end hover:text-gold"
          >
            <span className="text-white">
              <img src={whitemsg} />
            </span>
            info@danielmwambonu.com
          </a>
          <div className="flex gap-2 mt-8 justify-center md:justify-end">
            {socialIcons.map((icon) => (
              <SocialMediaButton
                imageUrl={icon.imageUrl}
                key={icon.id}
                href={icon.path}
                iconClassName="hover:bg-gold rounded-full"
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-center h-12 border-t-2 border-t-content">
        <p>Copyright © {new Date().getFullYear()} Daniel Mwambonu</p>
      </div>
    </div>
  );
}

export default BaseFooterSection;
