import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import RequirementsView from './RequirementsView';

export const RequirementsContainer = () => {
  return (
    <PageWrapper>
      <RequirementsView />
    </PageWrapper>
  );
};
