import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import PrivacyView from './PrivacyView';

export const PrivacyContainer = () => {
  return (
    <PageWrapper>
      <PrivacyView />
    </PageWrapper>
  );
};
