import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import PartnerView from './PartnerView';

export const PartnerContainer = () => {
  return (
    <PageWrapper>
      <PartnerView />
    </PageWrapper>
  );
};
