import React from 'react';
import PropTypes from 'prop-types';

function ServicesHeroCard({ height, image, title, subTitle, custom_styles, children }) {
  return (
    <div className="w-full relative">
      <figure>
        <img src={image} className={`w-full object-cover h-24 ${height ? height : 'md:h-96'}`} />
      </figure>
      <div
        className={`bg-black  flex flex-col items-center justify-center absolute top-0 w-full h-full bg-opacity-75 ${custom_styles}`}
      >
        <h2 className="text-gold text-lg md:text-6xl font-bold">{title}</h2>
        <p className="text-white font-medium text-sm md:text-3xl">{subTitle}</p>
        {children}
      </div>
    </div>
  );
}

ServicesHeroCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  custom_styles: PropTypes.string,
  height: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.any
};

export default ServicesHeroCard;
