import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import BiographyView from './BiographyView';

export const BiographyContainer = () => {
  return (
    <div className="bg-white">
      <PageWrapper>
        <BiographyView />
      </PageWrapper>
    </div>
  );
};
