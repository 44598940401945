import React from 'react';
import { ContactSection, BaseFooterSection } from './footerSections';
import { SubscribeForm } from 'components/modules/forms';

function PageFooter() {
  return (
    <div>
      <section>
        <SubscribeForm />
      </section>
      <section>
        <ContactSection />
      </section>
      <section>
        <BaseFooterSection />
      </section>
    </div>
  );
}

export default PageFooter;
