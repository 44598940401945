import React from 'react';
import PropTypes from 'prop-types';
import { hr_consulting_img } from 'assets/images';
import { Button } from 'components/widgets/buttons';

function HrConsultation({ children }) {
  const [HeroSection, ...Donate] = children;
  return (
    <>
      <div className="w-full">{HeroSection}</div>

      <div className="flex flex-col bg-white items-center justify-center md:px-20 lg:px-40 px-8 py-16">
        <div className="flex flex-col items-center justify-center mx-2">
          <h3 className="w-full text-gold font-semibold text-center md:justify-center text-3xl  md:text-5xl py-5 ">
            <span className="hidden md:inline">Requirements for </span> HR Consulting
          </h3>
          <div className="md:hidden">
            <figure className="w-full h-98">
              <img src={hr_consulting_img} className=" rounded-xl md:hidden" />
            </figure>
          </div>
          <div className=" text-justify">
            <p className="text-main font-normal text-base md:text-2xl py-2 md:inline mr-1">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officiat. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officiat.Amet minim mollit non de, HR consulting
            </p>
            <p className="text-main font-normal text-base md:text-2xl py-2 md:inline mr-1">
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officiat. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor
              do amet sint. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
              sint. Velit officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officiat. Amet minim mollit non de, HR consulting
            </p>
            <p className="text-main font-normal text-base md:text-2xl py-2 md:inline mr-1">
              ullamco est sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non de, HR
              consulting ullamco est sit aliqua dolor do amet sint.
            </p>
          </div>

          <Button className="my-10 btn w-[250px] h-[32px] md:w-[512px] md:h-[54px] normal-case text-gold border border-gold bg-white hover:bg-gold hover:text-white hover:border-gold">
            <span className="md:hidden">Enroll Now</span>
            <span className="hidden md:block">Get A Free Consultation</span>
          </Button>
        </div>
      </div>

      <div className="w-full">{Donate}</div>
    </>
  );
}

HrConsultation.propTypes = {
  children: PropTypes.any
};

export default HrConsultation;
