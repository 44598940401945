import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PdfButton } from 'components/widgets/buttons';
import { PdfModal } from 'components/modules/modals';

function PressCard({ imageURL, title, content }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div className="md:w-[586px] w-full md:h-[673px] h-[508px] shadow-md rounded-md pb-12">
      <div className="md:h-80 h-56 bg-[#99CEB2] rounded-t-md">
        <img src={imageURL} className="md:h-80 h-56 md:w-[586px] w-full" />
      </div>
      <div className="md:pt-12 py-4 md:px-20 px-4 ">
        <h2 className="md:text-4xl text-2xl font-semibold text-gold md:h-12 h-6 text-center pb-2">
          {title}
        </h2>
        <p className="md:text-base text-md text-card font-normal text-center md:h-28 h-32 pt-4">
          {content}
        </p>
      </div>
      <div className="text-center ">
        <PdfButton
          // to={pdfUrl}
          // download
          // target="_blank"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Download PDF
        </PdfButton>
      </div>
      {modalOpen ? <PdfModal setOpenModal={setModalOpen} /> : null}
    </div>
  );
}
PressCard.propTypes = {
  imageURL: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  pdfUrl: PropTypes.string,
  setModalOpen: PropTypes.bool
};

export default PressCard;
