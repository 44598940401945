import React from 'react';
import { DonateHeroImg } from 'assets/images';

function HeroSection() {
  return (
    <div
      className="w-full md:h-[500px] h-[300px] md:bg-Hero bg-HeroSm"
      style={{ background: `url(${DonateHeroImg})` }}
    >
      <div className="flex flex-col w-full h-96 items-center justify-center px-8 md:px-40">
        <h1 className="text-gold md:text-4xl text-2xl font-bold text-center mb-4">
          As we are part of various non profit organizations
        </h1>
        <p className="md:text-3xl text-base text-white font-medium text-center">
          Any SUPPORT from you helps us continue to make an impact and change for the future
        </p>
      </div>
    </div>
  );
}

export default HeroSection;
