import { EventPaginateData } from 'components/data';
import { EventsCard, EventsCardContainer } from 'components/modules/cards';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const postsPerPage = 9;

const EventsSection = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [videosData, setVideosData] = useState([]);

  useEffect(() => {
    const data = EventPaginateData;
    setVideosData([...data]);
  }, []);

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * postsPerPage;

  const eventCards = videosData.slice(offset, offset + postsPerPage).map((videoItem) => {
    return (
      <EventsCard
        views={videoItem.views}
        description={videoItem.description}
        date={videoItem.date}
        viewers={videoItem.viewers}
        key={videoItem.id}
        eventLink={''}
      />
    );
  });
  const pageCount = Math.ceil(videosData.length / postsPerPage);

  return (
    <div className="md:px-40 px-8 pt-12 pb-10">
      <h2 className="text-main my-12 text-6xl font-semibold">Past Events</h2>
      <EventsCardContainer>
        {eventCards}

        <div className=" flex col-span-full items-center justify-center my-12">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'btn-group flex-nowrap gap-2 no-scrollbar justify-center'}
            pageClassName={
              'btn btn-ghost btn-square btn-sm border-gold bg-theme hover:bg-gold hover:text-white rounded-md'
            }
            pageLinkClassName={
              'w-full h-full flex gap-4 items-center justify-center border-gold bg-white hover:bg-gold hover:text-white'
            }
            previousClassName={
              'btn btn-ghost btn-square btn-sm bg-theme border-theme hover:bg-gold hover:border-gold hover:text-white rounded-md'
            }
            previousLinkClassName={
              'w-full h-full flex gap-4 items-center justify-center rounded-md'
            }
            nextClassName={
              'btn btn-ghost btn-square btn-sm border-gold hover:text-white bg-gold text-white rounded-md'
            }
            nextLinkClassName={'w-full h-full flex gap-4 items-center justify-center rounded-md'}
            breakClassName={'btn btn-ghost btn-square btn-sm border-gold'}
            breakLinkClassName={'w-full h-full flex gap-4 items-center justify-center rounded-md'}
            activeClassName={'bg-gold'}
          />
        </div>
      </EventsCardContainer>
    </div>
  );
};

export default EventsSection;
