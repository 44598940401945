import { pdfIcon } from 'assets/images';
import {
  AHMPROCLAMATION,
  AHMSpeech,
  BiddenHarrisPress,
  BMHMJusticeForKenion,
  GhanaHQALD58,
  GHANAHQPAP
} from 'assets/pdf';

const data = [
  {
    id: 1,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: AHMPROCLAMATION
  },
  {
    id: 2,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: AHMSpeech
  },
  {
    id: 3,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: BiddenHarrisPress
  },
  {
    id: 4,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: BMHMJusticeForKenion
  },
  {
    id: 5,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: GhanaHQALD58
  },
  {
    id: 6,
    image: pdfIcon,
    title: 'How to do main text',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfFile: GHANAHQPAP
  }
];
export default data;
