export default function partnerSchema(values) {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = 'Full Name is required';
  } else if (values.fullName.length < 3) {
    errors.fullName = 'Invalid Name';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.company) {
    errors.company = 'Company Name is required';
  } else if (values.company.length < 3) {
    errors.company = 'Invalid Company Name';
  }

  if (!values.website) {
    errors.website = 'Website is required';
  } else if (values.website.length < 4) {
    errors.website = 'Invalid website. website must be at least 4 characters';
  } else if (!/[A-Za-z0-9]+/i.test(values.website)) {
    errors.website = 'Invalid website';
  }
  return errors;
}
