export const data = [
  {
    id: 1,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 2,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 3,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 4,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 5,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 6,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 7,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 8,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  },
  {
    id: 9,
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022'
  }
];
export default data;
