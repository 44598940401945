import React from 'react';

const AnnouncementInfoSection = () => {
  return (
    <div>
      <p>Announcements Go Here</p>
    </div>
  );
};

export default AnnouncementInfoSection;
