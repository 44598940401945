import React from 'react';
import { SocialMediaButton } from 'components/widgets/buttons';
import { twitter, facebook, youtube, linkedin, instagram, goldCalendar } from 'assets/icons';

function ContactSection() {
  const socialIcons = [
    { id: 1, imageUrl: twitter, path: 'https://twitter.com/DMwambonu' },
    { id: 2, imageUrl: youtube, path: 'https://www.youtube.com/channel/UCjFdVEbi9lwnCf93yCvgqEA' },
    { id: 3, imageUrl: facebook, path: 'https://www.facebook.com/KingMenelikIII' },
    {
      id: 4,
      imageUrl: linkedin,
      path: 'https://www.linkedin.com/in/daniel-mwambonu-king-menelik-iii91b185172/'
    },
    { id: 5, imageUrl: instagram, path: 'https://instagram.com/danielmwambonu' }
  ];

  return (
    <div className="w-full border-t-2 border-t-gray-200 py-12 md:px-40 px-4 group">
      <div className="md:mb-4 mb-4 md:text-left text-center">
        <h1 className="text-main text-4xl font-semibold">Contact</h1>
      </div>

      <div className="grid grid-rows-1 md:grid-cols-2 ">
        <div className=" md:ml-0 mx-auto w-full md:border-r-2 md:border-r-gray-200 md:py-4 pb-12 text-center my-auto">
          <div className="md:mb-2 mb-4">
            <p className="text-base font-normal md:text-xl md:text-justify text-main  text-center">
              Follow Daniel
            </p>
          </div>
          <div className="flex md:justify-start justify-center space-x-5">
            {socialIcons.map((icon) => (
              <SocialMediaButton
                imageUrl={icon.imageUrl}
                key={icon.id}
                href={icon.path}
                iconClassName="hover:bg-gold py-2 px-2 shadow-lg rounded-full"
              />
            ))}
          </div>
        </div>

        <div className="w-full md:py-4 py-12 md:text-left text-center md:pl-24 my-auto">
          <p className="text-base font-normal md:text-xl text-center md:text-justify text-main mb-2">
            Book a calendly meeting.
          </p>
          <div className="">
            <a
              href="https://calendly.com/mwambonudaniel"
              target="_blank"
              className="text-gold text-xl font-medium flex justify-center md:justify-start gap-2 items-center hover:text-green"
              rel="noreferrer"
            >
              <span>
                <img src={goldCalendar} />
              </span>
              To set up a meeting with Daniel
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
