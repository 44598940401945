import React, { createContext, useState } from 'react';
import Step from './Step';

export const ConnectContext = createContext();

function Context() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});
  return (
    <ConnectContext.Provider value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
      <Step />
    </ConnectContext.Provider>
  );
}

export default Context;
