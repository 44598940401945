import React, { useContext } from 'react';
import { arrowRight, envelope, person } from 'assets/icons';
import { Form, Formik } from 'formik';
import { InputFormGroup, InputFormNoIcon } from 'components/modules/forms';
import { partnerSchema } from 'validations';
import { PartnerContext } from './Context';

function ContactForm() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(PartnerContext);
  const formikInitialValues = {
    fullName: '',
    email: '',
    company: '',
    website: ''
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };
  return (
    <div className=" md:w-[600px] w-full shadow-lg rounded-lg">
      <Formik
        initialValues={formikInitialValues}
        validate={partnerSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form className="md:p-8 p-4 md:h-[800px] h-full flex flex-col md:gap-8 gap-4">
          <InputFormGroup
            type="text"
            nameAndId="fullName"
            label="Full Name"
            placeholder="Enter Full Name"
            iconUrl={person}
          />

          <InputFormGroup
            type="email"
            nameAndId="email"
            label="Email"
            placeholder="enter your email"
            iconUrl={envelope}
          />
          <InputFormNoIcon
            type="text"
            nameAndId="company"
            label="Company/ Organization Name"
            labelClassName="text-main"
            placeholder="Enter Company/ Organization Name"
          />
          <InputFormNoIcon
            type="text"
            nameAndId="website"
            label="Company Website"
            labelClassName="text-main"
            placeholder="Enter Company Website"
          />
          <button
            type="submit"
            className="bg-gold hover:bg-main md:py-3 py-2 px-8 w-full flex md:gap-4 gap-2 text-white items-center justify-center rounded-md md:mt-20 mt-4 md:text-lg text-sm font-semibold"
          >
            Next
            <img src={arrowRight} className="md:h-6 h-4 md:w-6 w-4" />
          </button>
          <p className="text-gold underline underline-offset-4 font-normal md:text-xl text-xs text-center md:mt-28 mt-4">
            Powered by Daniel
          </p>
        </Form>
      </Formik>
    </div>
  );
}

export default ContactForm;
