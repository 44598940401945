import React from 'react';
import { msg } from 'assets/images';
import { whatsapp } from 'assets/icons';

function PageHeader() {
  return (
    <div className=" flex flex-col md:flex-row md:py-2 py-1 md:gap-12 gap-1  justify-center items-center bg-white text-main ">
      <div className="flex bg-white justify-center text-main py-2 items-center">
        <p className="md:text-lg text-[10px]">
          An Official website for the President of the United States of Africa.
        </p>
        <span className="mt-1 md:pl-2 pl-1 ">
          <div className="bg-[#CD0000] md:h-[0.37rem] h-[4px] w-6 md:w-8"></div>
          <div className="bg-[#1a1a1a] h-1 w-6 md:w-8"></div>
          <div className="bg-[#4D9234] md:h-[0.37rem] h-[4px] w-6 md:w-8"></div>
        </span>
      </div>
      <div className="flex md:gap-12 gap-4">
        <div className="flex items-center ">
          <span>
            <img src={msg} className="md:pr-2 pr-1 md:pl-0 pl-2 md:h-4 h-2" />
          </span>
          <a href="mailto:info@danielmwambonu.com" className="md:text-lg text-[10px]">
            info@danielmwambonu.com
          </a>
        </div>
        <div className="flex items-center ">
          <img src={whatsapp} className="md:pr-2 pr-1 md:pl-0 pl-2 md:h-6 h-3" />
          <a href="https://wa.me/254700614290" className="md:text-lg text-[10px]">
            +254 70-0614290
          </a>
        </div>
      </div>
    </div>
  );
}

export default PageHeader;
