import env from 'configs';
export const DANIEL_USER_DATA = 'daniel_user_data';
export const RESPONSE_SUCCESS = 'SUCCESS';
export const RESPONSE_ERROR = 'FAILURE';

export const CONNECT = 'connect';

/**
 * API Endpoints for the application to be used in the services layer of the application
 */

export const FAQ = 'faq';
export const USER = 'users';
export const HOLIDAY = 'holiday';
export const DEPARTMENT = 'departments';
export const PROGRAM = 'programs';

export const DONATE = `${env.API_BASE_URL}donate`;
export const UPDATE_DONATE = `${env.API_BASE_URL}donate/update/`;
export const GET_DONATION = `${env.API_BASE_URL}donate/`;

export const DONATE_TYPE = {
  once: 'single',
  monthly: 'monthly',
  quarterly: 'quarterly',
  annually: 'annually'
};

export const DONATE_STATUS = {
  success: 'succeeded',
  processing: 'processing',
  failed: 'payment_failed'
};

export const DONATE_CONFIRM_MESSSAGE = {
  success: 'Thank you for your donation...',
  processing: "Donation is processing.... We'd get back to you if successful.",
  failed:
    'Unable to Process your donation, kindly try again with another payment method or Card.. Thank you'
};
