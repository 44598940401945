import React from 'react';
import { CardSection, HeroSection, SubImgSection } from 'components/layout/Organization';

function OrganizationView() {
  return (
    <div>
      <HeroSection />
      <CardSection />
      <SubImgSection />
    </div>
  );
}

export default OrganizationView;
