import React from 'react';
import { calender } from 'assets/icons';
import PropTypes from 'prop-types';

const UpcomingEventsCard = ({ date, description }) => {
  return (
    <div className="h-80 md:w-80 w-full rounded-lg shadow-md flex flex-col">
      <div className="bg-ash h-40 w-full rounded-t-md"></div>
      <div className="bg-gold h-40 w-full rounded-b-md">
        <div className="relative px-4 -mt-6 ">
          <div className="bg-white h-40 md:w-72 w-full px-1 md:py-6 py-2 text-center">
            <div className="flex justify-between pb-6">
              <p className="text-base text-ash flex gap-2 items-center">
                <span>
                  <img src={calender} className="h-4" />
                </span>
                {date}
              </p>
            </div>
            <div>
              <p className="text-base font-normal text-main text-center">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UpcomingEventsCard.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string
};

export default UpcomingEventsCard;
