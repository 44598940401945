import React from 'react';
import { PageWrapper } from 'components/modules/wrapper';
import {
  BottomCardSection,
  CardSection,
  FeaturedNewsSection,
  FullStorySection,
  HeroSection,
  SubImgSection
} from 'components/layout/News';
import PdfSection from 'components/layout/News/pdfSection';

function NewsView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection />
      </section>
      <section>
        <FeaturedNewsSection />
      </section>
      <section>
        <CardSection />
      </section>
      <section>
        <FullStorySection />
      </section>
      <section>
        <BottomCardSection />
      </section>
      <section>
        <SubImgSection />
      </section>
      <section>
        <PdfSection />
      </section>
    </PageWrapper>
  );
}

export default NewsView;
