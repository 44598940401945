import React from 'react';
import { Button } from 'components/widgets/buttons';
import { BIOGRAPHY } from 'navigation/CONSTANTS';

function BiographySection() {
  return (
    <div className="flex items-center justify-center flex-col w-full py-12 md:px-64 px-8  ">
      <p className="text-main font-bold text-5xl mb-8">Biography</p>
      <p className="text-gold font-bold text-2xl mb-2">Daniel Mwambonu</p>
      <p className="text-main font-bold text-2xl mb-2 text-center">
        Daniel Mwambonu is an African International Human Rights Activist, Mental Health Advocate,
      </p>
      <p className="text-green font-bold text-2xl mb-8 text-center">
        Pan Africanist and an African Nationalist.
      </p>
      <div className=" md:w-[50%] w-full flex items-center justify-center ">
        <Button
          to={BIOGRAPHY}
          className="py-3 rounded-md px-4 hover:bg-black mx-auto w-full text-white text-center bg-gold"
        >
          Read More
        </Button>
      </div>
    </div>
  );
}

export default BiographySection;
