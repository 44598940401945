import React from 'react';
import PropTypes from 'prop-types';
import { arrowLeft } from 'assets/icons';

function CarouselArrowButtonLeft() {
  return (
    <div className=" hover:translate-x-1 transition-all">
      <button className="" onClick={() => onclick()}>
        <img src={arrowLeft} />
      </button>
    </div>
  );
}

CarouselArrowButtonLeft.propTypes = {
  onClick: PropTypes.func
};

export default CarouselArrowButtonLeft;
