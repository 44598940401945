import React from 'react';
import PropTypes from 'prop-types';
import { calender, eye } from 'assets/icons';
// import YouTube from 'react-youtube';

function VideoCard({ title, description, viewers, date, image }) {
  // const opts = {
  //   height: '384',
  //   width: '384'
  // };

  return (
    <div className="shadow-md md:w-96 w-full rounded-md">
      <div className="md:h-96 h-80 md:w-96 w-full bg-[#DBF5E8] overflow-hidden rounded-t-md">
        <img src={image} />
      </div>
      <h2 className="text-gold text-3xl font-semibold text-center py-4 px-2">{title}</h2>
      <p className="text-lg font-normal text-center px-2 pt-2 pb-4">{description}</p>
      <div className="grid grid-cols-2 border-t-2 border-t-ash  p-4">
        <div className="flex items-center gap-2 border-r-2 border-r-ash">
          <img src={calender} className=" h-4" />
          <p className="text-base">{date}</p>
        </div>
        <div className="flex items-center gap-2 md:pl-12 pl-8">
          <img src={eye} className=" h-4" />
          <p className="text-gold text-base">{viewers}</p>
        </div>
      </div>
    </div>
  );
}

VideoCard.propTypes = {
  videoId: PropTypes.string,
  video: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  viewers: PropTypes.string,
  image: PropTypes.string
};

export default VideoCard;
