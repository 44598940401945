import React from 'react';
import { PageWrapper } from 'components/modules/wrapper';
import { HeroSection, SubImgSection, PdfSection } from 'components/layout/Videos';
import CardSection from './CardSection';
// import CardSection from './CardSection';

function VideosView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection />
      </section>
      <section>
        <CardSection />
      </section>
      <section>
        <SubImgSection />
      </section>
      <section>
        <PdfSection />
      </section>
    </PageWrapper>
  );
}

export default VideosView;
