import React, { useState, useEffect } from 'react';
import { PdfCard } from 'components/modules/cards';
import { PdfData } from 'components/data';

function PdfSection() {
  const [pdfData, setPdfData] = useState([]);
  const [viewAll, setViewAll] = useState(false);

  useEffect(() => {
    const data = PdfData;
    setPdfData([...data]);
  }, []);

  const handleEventClick = () => {
    setViewAll(!viewAll);
  };

  const allPdf = pdfData.map((news) => {
    return (
      <PdfCard
        key={news.id}
        image={news.image}
        title={news.title}
        content={news.content}
        pdfFile={news.pdfFile}
      />
    );
  });

  return (
    <div className="py-20 md:px-40 px-8">
      <div className="flex justify-between items-center gap-8">
        <h2 className="md:text-6xl text-2xl text-main font-medium tracking-wide">
          Get PDF slides of our past events
        </h2>
        <button
          onClick={handleEventClick}
          className="text-gold font-semibold md:text-2xl text-xl w-32"
        >
          {viewAll ? 'Hide all' : 'See all'}
        </button>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-8 mt-12">
        {viewAll
          ? allPdf
          : pdfData
              .sort(() => Math.random() - Math.random())
              .slice(0, 3)
              .map((news) => {
                return (
                  <PdfCard
                    key={news.id}
                    image={news.image}
                    title={news.title}
                    content={news.content}
                    pdfFile={news.pdfFile}
                  />
                );
              })}
      </div>
    </div>
  );
}

export default PdfSection;
