import React from 'react';
import { QuoteSection } from './components';
import Context from './components/Context';

function ConnectView() {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 md:gap-12 gap-8 py-12 md:px-40 px-8">
      <QuoteSection />
      <Context />
    </div>
  );
}

export default ConnectView;
