import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '.';

function LongGoldButton(props) {
  const { children } = props;

  return (
    <Button {...props} className="bg-gold hover:bg-main py-3 px-6 rounded text-white text-center ">
      {children}
    </Button>
  );
}

LongGoldButton.propTypes = {
  children: PropTypes.any
};
export default LongGoldButton;
