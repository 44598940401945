import React from 'react';

const SecurityDefense = () => {
  return (
    <div className="my-12">
      <h5 className="py-2 text-3xl font-semibold  sm:font-bold ">Security and Defense</h5>
      <p className="text-base font-normal sm:text-2xl text-justify  py-3">
        He has in many instances called for the removal of colonial boundaries, withdrawal of all
        foreign military bases in Africa and insisted on{' '}
        <span className="text-gold">former colonial powers to respect Africa’s sovereignty</span>,
        however he has no problem if there is a reciprocatory gesture to allow African countries to
        establish their own military bases in the US or Europeans countries in so called{' '}
        <span className="text-gold">security cooperation</span>.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He has called on the establishment of{' '}
        <span className="text-gold">African Defense Forces</span> which will work on protecting
        Africa as a whole from external aggression and also scalling up war on terror to ensure
        sustainable peace is achieved and the <span className="text-gold">African Union goal</span>{' '}
        of silencing of guns is realized.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        Africa has enough capable men and women to handle its security threats or affairs with right
        leadership onboard.
      </p>
    </div>
  );
};

export default SecurityDefense;
