import React from 'react';
import PropTypes from 'prop-types';
import { mediaimg } from 'assets/images';

function HeroSection({ headingText }) {
  return (
    <div className="w-full">
      <figure>
        <img src={mediaimg} className="w-full object-cover h-96" />
      </figure>
      <div className="absolute top-36 left-0 flex flex-col w-full h-96 items-center justify-center">
        <h1 className="text-gold text-6xl font-extrabold text-center mb-4">{headingText}</h1>
        <p className="text-2xl text-white font-medium text-center">
          View Live, Past and Upcoming events
        </p>
      </div>
    </div>
  );
}

HeroSection.propTypes = {
  headingText: PropTypes.string
};

export default HeroSection;
