import React from 'react';
import Context from './Context';

function RequestSection() {
  return (
    <div className="flex md:flex-row flex-col-reverse md:gap-28 gap-8 md:px-40 px-6 md:pb-32 pb-12">
      <div className="flex flex-col md:gap-12 gap-4 ">
        <h2 className="md:text-6xl text-2xl font-normal text-main md:pt-40 pt-12 md:text-left text-center">
          Make a <br />
          <span className="text-gold md:text-6xl text-2xl font-medium">Donation</span> <br />
          Today!
        </h2>
        <p className="text-main md:text-3xl text-base md:text-left text-center font-normal md:w-[434px] w-[330px] mx-auto md:mx-0">
          Please, input your personal details, so we can have your details with us.
        </p>
      </div>
      <div>
        <Context />
      </div>
    </div>
  );
}

export default RequestSection;
