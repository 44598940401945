import React from 'react';
import PropTypes from 'prop-types';

function OrganizationCarouselCard({ image, title, content, btnUrl }) {
  return (
    <div className="bg-white rounded sm:w-96 w-[95%] h-96 p-2">
      <img src={image} alt={title} className="h-20 w-20 mx-auto mt-4" />
      <h3 className="text-main font-bold sm:text-xl text-lg text-center my-2 mx-4">{title}</h3>
      <p className="text-main sm:text-xl text-base text-center mx-4 h-40 mb-4 ">{content}</p>
      <div className="flex items-center justify-center ">
        <a
          className="text-green  decoration-green underline underline-offset-2"
          href={btnUrl}
          target="_blank"
          rel="noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  );
}
OrganizationCarouselCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  btnUrl: PropTypes.string
};

export default OrganizationCarouselCard;
