import React from 'react';
import PropTypes from 'prop-types';

function ServiceItemCard({ custom_styles, children }) {
  return (
    <div className="w-full flex flex-col relative md:my-16 my-4">
      <div className={`flex flex-col md:flex-row ${custom_styles} mt-12 md:mt-0  md:gap-16 gap-4`}>
        {children}
      </div>
    </div>
  );
}

ServiceItemCard.propTypes = {
  children: PropTypes.any,
  custom_styles: PropTypes.string
};
export default ServiceItemCard;
