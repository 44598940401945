import { HeroSection } from 'components/layout/Events';
import { MissionSection } from 'components/layout/PastEvents';
import { EventsSection } from 'components/layout/UpcomingEvents';
import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';

function UpcomingEventsView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection headingText="Upcoming Events" />
      </section>
      <section>
        <EventsSection />
      </section>
      <section>
        <MissionSection />
      </section>
    </PageWrapper>
  );
}

export default UpcomingEventsView;
