import { EventPaginateData } from 'components/data';
import { EventsCard } from 'components/modules/cards';
import React, { useEffect, useState } from 'react';

const EventsSection = () => {
  const [videosData, setVideosData] = useState([]);

  useEffect(() => {
    const data = EventPaginateData;
    setVideosData([...data]);
  }, []);

  return (
    <div className="md:px-40 px-8 text-main">
      <h2 className="font-medium text-2xl pb-0">Past Event Recordings </h2>
      <p className="md:text-lg text-base">
        These recordings will be available on Daniel’s website.
      </p>
      <div className="grid md:grid-cols-3 grid-cols-1 md:gap-12  gap-8 my-12">
        {videosData.slice(0, 3).map((videoItem) => {
          return (
            <EventsCard
              views={videoItem.views}
              description={videoItem.description}
              date={videoItem.date}
              viewers={videoItem.viewers}
              eventLink={''}
              key={videoItem.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventsSection;
