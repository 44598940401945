import React from 'react';
import { PageWrapper } from 'components/modules/wrapper';
import {
  HeroSection,
  BiographySection,
  OrganizationSection,
  ServicesSection,
  QuotesSection,
  TestimonialSection
} from 'components/layout/Home';

function HomeView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection />
      </section>
      <section>
        <BiographySection />
      </section>
      <section>
        <ServicesSection />
      </section>
      <section>
        <OrganizationSection />
      </section>
      <section>
        <QuotesSection />
      </section>
      <section>
        <TestimonialSection />
      </section>
    </PageWrapper>
  );
}

export default HomeView;
