export default function connectSchema(values) {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = 'Full Name is required';
  } else if (values.fullName.length < 3) {
    errors.fullName = 'Invalid Name';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.message) {
    errors.message = 'Message is required';
  } else if (values.message.length < 10) {
    errors.message = 'Invalid message. Message must be at least 10 characters';
  } else if (!/[A-Za-z0-9]+/i.test(values.message)) {
    errors.message = 'Invalid message';
  }

  return errors;
}
