import React from 'react';
import { PageWrapper } from 'components/modules/wrapper';
import { HeroSection } from 'components/layout/Events';
import { EventsSection, MissionSection } from 'components/layout/PastEvents';

function PastEventsView() {
  return (
    <PageWrapper>
      <section>
        <HeroSection headingText="Past Events" />
      </section>
      <section>
        <EventsSection />
      </section>
      <section>
        <MissionSection />
      </section>
    </PageWrapper>
  );
}

export default PastEventsView;
