import React, { useContext } from 'react';
import ChooseAmount from './ChooseAmount';
import { DonationContext } from './Context';
import Information from './Information';
import Payment from './Payment';
import Success from './Success';

function Step() {
  const { activeStepIndex } = useContext(DonationContext);
  switch (activeStepIndex) {
    case 0:
      return <ChooseAmount />;
    case 1:
      return <Information />;
    case 2:
      return <Payment />;
    case 3:
      return <Success />;
    default:
      break;
  }
}

export default Step;
