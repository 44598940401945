import { bg404 } from 'assets/images';
import { Button } from 'components/widgets/buttons';
import { CONNECT } from 'navigation/CONSTANTS';
import React from 'react';
function ErrorPageView() {
  return (
    <div className="md:h-[81vh] h-[82vh] w-full flex items-center justify-center bg-white md:px-40 px-8">
      <div className="flex flex-col gap-8 items-center justify-center">
        <div className="relative flex items-center justify-center">
          <img src={bg404} />
          <div className="text-white absolute md:top-24 top-16">
            <p className="text-9xl font-bold text-center">404</p>
            <p className="text-5xl text-center font-bold">Error</p>
          </div>
        </div>
        <p className="md:text-2xl text-base text-main text-center md:w-[689px] w-full">
          The Page you seek has been removed or redirected. Click on “Back to home” below to return
          to Connect with Daniel
        </p>
        <Button
          to={CONNECT}
          className="btn bg-gold border-gold tracking-wide font-semibold py-3  text-white hover:bg-main md:px-20 px-12"
        >
          Connect with Daniel
        </Button>
      </div>
    </div>
  );
}

export default ErrorPageView;
