const SVG_PATH = '/assets/svg';
export const checkboxMarkedCircle = `${SVG_PATH}/checkbox-marked-circle.svg`;
export const chevronRightCircleOutlined = `${SVG_PATH}/chevron-right-circle-outlined.svg`;
export const add = `${SVG_PATH}/add.svg`;
export const instagram = `${SVG_PATH}/instagram.svg`;
export const facebook = `${SVG_PATH}/facebook.svg`;
export const twitter = `${SVG_PATH}/twitter.svg`;
export const youtube = `${SVG_PATH}/youtube.svg`;
export const linkedin = `${SVG_PATH}/linkedin.svg`;
export const whatsapp = `${SVG_PATH}/whatsapp.svg`;
export const instagramDark = `${SVG_PATH}/instagramDark.svg`;
export const facebookDark = `${SVG_PATH}/facebookDark.svg`;
export const twitterDark = `${SVG_PATH}/twitterDark.svg`;
export const youtubeDark = `${SVG_PATH}/youtubeDark.svg`;
export const linkedinDark = `${SVG_PATH}/linkedinDark.svg`;
export const whatsappDark = `${SVG_PATH}/whatsappDark.svg`;
export const scrollToTop = `${SVG_PATH}/scrollToTop.svg`;
export const calender = `${SVG_PATH}/calender.svg`;
export const users = `${SVG_PATH}/users.svg`;
export const clock = `${SVG_PATH}/clock.svg`;
export const eye = `${SVG_PATH}/eye.svg`;
export const whiteEye = `${SVG_PATH}/whiteEye.svg`;
export const flag = `${SVG_PATH}/flag.svg`;
export const goldCalendar = `${SVG_PATH}/goldCalendar.svg`;
export const settings = `${SVG_PATH}/settings.svg`;
export const recording = `${SVG_PATH}/recording.svg`;
export const expandCircle = `${SVG_PATH}/expandCirlce.svg`;
export const chatCircle = `${SVG_PATH}/chatCircle.svg`;
export const eclipse = `${SVG_PATH}/eclipse.svg`;
export const chat = `${SVG_PATH}/chat.svg`;
export const circle = `${SVG_PATH}/circle.svg`;
export const health = `${SVG_PATH}/health.svg`;
export const smileyFace = `${SVG_PATH}/smileyFace.svg`;
export const send = `${SVG_PATH}/send.svg`;
export const arrowRight = `${SVG_PATH}/arrowRight.svg`;
export const arrowLeft = `${SVG_PATH}/arrowLeft.svg`;
export const arrowBlackRight = `${SVG_PATH}/arrowBlackRight.svg`;
export const arrowBlackLeft = `${SVG_PATH}/arrowBlackLeft.svg`;
export const envelope = `${SVG_PATH}/envelope.svg`;
export const close = `${SVG_PATH}/close.svg`;
export const heart = `${SVG_PATH}/heart.svg`;
export const lock = `${SVG_PATH}/lock.svg`;
export const person = `${SVG_PATH}/person.svg`;
