import { GPANLOGO, BWC, USAF, BMHM, ABM, ABS, AHM2 } from 'assets/images';

const data = [
  {
    id: 1,
    image: GPANLOGO,
    title: 'Global Pan Africanism Network',
    btnUrl: 'https://www.gpanafrica.org',
    content:
      '(GPAN) is an International Human Rights and Pan African organization to reunite all people of African descent, advocate for their rights and freedoms throughout the world.'
  },
  {
    id: 2,
    image: USAF,
    title: 'United States of Africa',
    btnUrl: 'https://www.usafrikagov.com',
    content:
      'is an Initiative working towards unification of all people of African descent under one political kingdom based in Africa.'
  },
  {
    id: 3,
    image: BWC,
    title: 'Black Woman Can',
    btnUrl: 'https://www.blackwomancan.com',
    content:
      'is a series to uplift Black Women around the world through showcasing the achievement of Black Women throughout History, capturing their daily struggles for Justice and Equality.'
  },
  {
    id: 4,
    image: GPANLOGO,
    title: 'Pan African Leadership Center',
    btnUrl: 'https://www.panafricanleadershipcenter.org',
    content:
      'nurtures young leaders, encourage women leadership and provide leadership training driven by Pan Africanism Principles to aspiring leaders of African Descent.'
  },
  {
    id: 5,
    image: ABM,
    title: 'African Business Month',
    btnUrl: 'https://www.africanbusinessmonth.org',
    content:
      'was established to focus on promoting African businesses in Africa, Europe, Caribbean, Pacific and round the world.'
  },
  {
    id: 6,
    image: ABS,
    title: 'African Business Show',
    btnUrl: '',
    content:
      'objective is to provide a platform for people of African descent to showcase their products and services, network, advertise and grow their businesses.'
  },
  {
    id: 7,
    image: BMHM,
    title: 'Black Mental Health Matters',
    btnUrl: 'https://www.bmhm.org',
    content:
      'Promotes and champions mental health research & rights for Africans, combats medical racism, creates and provides needed infrastructure for trauma healing.'
  },
  {
    id: 8,
    image: AHM2,
    title: 'African History Month',
    btnUrl: 'https://www.ahm.africa',
    content:
      'will encourage and promote further research, celebrate achievements all people of African descent including where Black History Month is not celebrated.'
  }
];

export default data;
