import React from 'react';
import propTypes from 'prop-types';
import { PageNavBar } from 'components/modules/navbars';
import { PageFooter } from 'components/modules/footers';
import { PageHeader } from 'components/modules/headers';

function PageWrapper({ children }) {
  return (
    <div className="relative w-full overflow-x-hidden bg-white font-poppins">
      <header className="fixed top-0 left-0 z-20 w-full drop-shadow-xl">
        <div className="w-full">
          <PageHeader />
        </div>
        <nav>
          <PageNavBar />
        </nav>
      </header>

      <main className=" sm:mt-36 mt-28">
        <div>{children}</div>
      </main>

      <footer>
        <div>
          <PageFooter />
        </div>
      </footer>
    </div>
  );
}

PageWrapper.propTypes = {
  children: propTypes.any
};

export default PageWrapper;
