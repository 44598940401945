import React from 'react';
import PropTypes from 'prop-types';

const MissionSection = ({ children }) => {
  return (
    <div className="md:px-20 lg:px-40 px-8 md:py-12 py-4">
      <h2 className="text-3xl font-bold sm:text-4xl py-4 ">Daniel’s Mission:</h2>
      {children}
    </div>
  );
};

MissionSection.propTypes = {
  children: PropTypes.any
};
export default MissionSection;
