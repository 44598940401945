import React from 'react';
import { calender, users, whiteEye } from 'assets/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const EventsCard = ({ date, description, viewers, views, eventLink }) => {
  return (
    <Link to={eventLink}>
      <div className="h-80 md:w-80 w-full rounded-lg shadow-md flex flex-col">
        <div className="bg-ash h-40 w-full rounded-t-md"></div>
        <div className="flex  justify-end">
          <div className="flex gap-2 items-center justify-center bg-green text-white w-20 h-9 -mt-36 p-2 mr-4">
            <span>
              <img src={whiteEye} />
            </span>
            <p className="md:text-base text-xs">{views}</p>
          </div>
        </div>
        <div className="bg-gold h-40 w-full rounded-b-md">
          <div className="relative px-4 -mt-6">
            <div className="bg-white h-40 md:w-72 w-full px-3 py-6 text-center">
              <div className="flex justify-between pb-6">
                <p className="text-base text-ash flex gap-2 items-center">
                  <img src={calender} className="h-4" />
                  <span className="text-base text-ash">{date}</span>
                </p>
                <p className="text-base text-green flex gap-2 items-center">
                  <img src={users} className="h-5" />
                  <span className="text-base text-green">{viewers}</span>
                </p>
              </div>
              <div>
                <p className="text-base font-normal text-main text-center">{description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

EventsCard.propTypes = {
  views: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  viewers: PropTypes.string,
  eventLink: PropTypes.string
};

export default EventsCard;
