const IMAGES_PATH = '/assets/images';

// Partners logo
export const partnerAripo = `${IMAGES_PATH}/partner_aripo.png`;
export const partnerImo = `${IMAGES_PATH}/partner_imo.png`;
export const partnerStripe = `${IMAGES_PATH}/partner_stripe.png`;
export const partnerWho = `${IMAGES_PATH}/partner_who.png`;
export const partnerArdso = `${IMAGES_PATH}/partner_ardso.png`;
export const GPANLOGO = `${IMAGES_PATH}/GPANLOGO.png`;
export const GPANQC = `${IMAGES_PATH}/GPANQC.jpeg`;
export const BMHM = `${IMAGES_PATH}/BMHM.png`;
export const BWC = `${IMAGES_PATH}/BWC.png`;
export const ABS = `${IMAGES_PATH}/ABS.png`;
export const SWWP = `${IMAGES_PATH}/SWWP.png`;
export const SBTM = `${IMAGES_PATH}/SBTM.png`;
export const IHEBA = `${IMAGES_PATH}/IHEBA.png`;
export const PALC = `${IMAGES_PATH}/PALC.jpeg`;
export const PALCLOGO = `${IMAGES_PATH}/PALCLOGO.png`;
export const FWPP = `${IMAGES_PATH}/FWPP.jpeg`;
export const Zumaridi = `${IMAGES_PATH}/zumaridi.png`;
export const AHM2 = `${IMAGES_PATH}/AHM2.png`;
export const ABM = `${IMAGES_PATH}/abm.png`;
export const USAF = `${IMAGES_PATH}/USAF.png`;
export const danielSm = `${IMAGES_PATH}/daniel-sm.png`;
export const organizations = `${IMAGES_PATH}/organizations.jpeg`;
export const bg404 = `${IMAGES_PATH}/bg404.png`;
export const privacyHeader = `${IMAGES_PATH}/privacyHeader.png`;
export const termsHeader = `${IMAGES_PATH}/termsHeader.png`;

export const africaMap = `${IMAGES_PATH}/africaMap.png`;
export const logo = `${IMAGES_PATH}/logo.png`;
export const flag = `${IMAGES_PATH}/flag.png`;
export const msg = `${IMAGES_PATH}/msg.png`;
export const phone = `${IMAGES_PATH}/phone.png`;
export const whitemsg = `${IMAGES_PATH}/whitemsg.png`;
export const whitephone = `${IMAGES_PATH}/whitephone.png`;
export const menu = `${IMAGES_PATH}/menu.png`;
export const close = `${IMAGES_PATH}/close.png`;
export const cta = `${IMAGES_PATH}/cta.png`;
export const background = `${IMAGES_PATH}/background.png`;
export const daniel = `${IMAGES_PATH}/daniel.png`;
export const testimg1 = `${IMAGES_PATH}/testimg1.png`;
export const testimg2 = `${IMAGES_PATH}/testimg2.png`;
export const testimg3 = `${IMAGES_PATH}/testimg3.png`;

//Services/PartnerWithUs pages
export const hr_consulting_img = `${IMAGES_PATH}/hr-consulting-img.png`;
export const product_design_img = `${IMAGES_PATH}/product-design-img.png`;
export const services_hero_bg = `${IMAGES_PATH}/services-hero-bg.png`;
export const software_dev_img = `${IMAGES_PATH}/software-dev-img.png`;
export const handshake = `${IMAGES_PATH}/handshake.jpeg`;
export const branding_img = `${IMAGES_PATH}/branding-img.png`;
export const donate_img = `${IMAGES_PATH}/donate-img.jpeg`;
export const TESTIMG1 = `${IMAGES_PATH}/TESTIMG1.png`;
export const TESTIMG2 = `${IMAGES_PATH}/TESTIMG2.png`;
export const TESTIMG3 = `${IMAGES_PATH}/TESTIMG3.png`;
export const mediaimg = `${IMAGES_PATH}/mediaimg.png`;
export const subIMG = `${IMAGES_PATH}/subIMG.png`;
export const usafPdf = `${IMAGES_PATH}/usafPdf.png`;
export const ahmLaunch = `${IMAGES_PATH}/ahmLaunch.png`;
export const ahmOfficial = `${IMAGES_PATH}/ahmOfficial.png`;
export const gpanCondemns = `${IMAGES_PATH}/gpanCondemns.png`;
export const gpanInvitation = `${IMAGES_PATH}/gpanInvitation.png`;
export const usafCongrats = `${IMAGES_PATH}/usafCongrats.png`;
export const usafQueens = `${IMAGES_PATH}/usafQueens.png`;
export const pdfIcon = `${IMAGES_PATH}/pdfIcon.png`;
export const videoIcon = `${IMAGES_PATH}/videoIcon.png`;

//Connect
export const successImg = `${IMAGES_PATH}/successImg.png`;

//Donate
export const DonateHeroImg = `${IMAGES_PATH}/DonateHeroImg.png`;
export const DonateHeroImgSm = `${IMAGES_PATH}/DonateHeroImgSm.png`;
