import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function InputFormGroup({
  nameAndId,
  placeholder,
  label,
  type,
  className,
  labelClassName,
  iconUrl
}) {
  return (
    <div className={`form-control ${className || 'w-full'}`}>
      <label className={`label ${labelClassName}`} htmlFor={nameAndId}>
        <span className="label-text text-main">{label}</span>
      </label>
      <div className="flex items-center">
        <img src={`${iconUrl}`} className="bg-ash py-[13.4px] px-3 rounded-l-md" />
        <Field
          name={nameAndId}
          id={nameAndId}
          as={'input'}
          placeholder={placeholder}
          type={type || 'text'}
          className={
            className || 'bg-theme border-theme w-full rounded-r-md placeholder-ash text-main'
          }
        />
      </div>
      <ErrorMessage
        component="label"
        name={nameAndId}
        className="label-text-alt w-full text-alerts-error-color"
      />
    </div>
  );
}
InputFormGroup.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconUrl: PropTypes.string
};

export default InputFormGroup;
