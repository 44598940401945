import { circle, health, users } from 'assets/icons';
import React, { useState } from 'react';
import AboutInfoSection from './AboutInfoSection';
import AdditionalInfoSection from './AdditionalInfoSection';
import AnnouncementInfoSection from './AnnouncementInfoSection';

const InfoSection = () => {
  const [tabOpen, setTabOpen] = useState(1);

  return (
    <div className="md:px-40 px-8 pt-12 pb-16">
      <h2 className="font-medium text-2xl tracking-[0.02rem] text-main">
        World Suicide Prevention Day Creating Hope through Action
      </h2>
      <div className="my-4 flex md:flex-row flex-col md:gap-8 gap-2 text-main">
        <div className="flex items-center gap-2">
          <img src={circle} />
          <p className="md:text-lg text-base">Daniel Mwambonu</p>
        </div>
        <div className="flex items-center gap-2">
          <img src={health} />
          <p className="md:text-lg text-base">Black Mental Health Matters</p>
        </div>
        <div className="flex items-center gap-2">
          <img src={users} />
          <span className="">|</span>
          <p className="md:text-lg text-base">132 watching</p>
        </div>
      </div>
      <div>
        <div className="flex mt-10 justify-between md:w-[600px] w-full">
          <button onClick={() => setTabOpen(1)}>
            <h3
              className={
                tabOpen === 1
                  ? 'md:text-2xl text-base font-bold text-gold'
                  : 'md:text-2xl text-base font-bold text-main'
              }
            >
              About
            </h3>
          </button>
          <button onClick={() => setTabOpen(2)}>
            <h3
              className={
                tabOpen === 2
                  ? 'md:text-2xl text-base font-bold text-gold'
                  : 'md:text-2xl text-base font-bold text-main'
              }
            >
              Announcement
            </h3>
          </button>
          <button onClick={() => setTabOpen(3)}>
            <h3
              className={
                tabOpen === 3
                  ? 'md:text-2xl text-base font-bold text-gold'
                  : 'md:text-2xl text-base font-bold text-main'
              }
            >
              Additional Content
            </h3>
          </button>
        </div>
        <div className="border-b-2 border-[#DDE0E6] my-2"></div>
        <div>{tabOpen === 1 && <AboutInfoSection />}</div>
        <div>{tabOpen === 2 && <AnnouncementInfoSection />}</div>
        <div>{tabOpen === 3 && <AdditionalInfoSection />} </div>
      </div>
    </div>
  );
};

export default InfoSection;
