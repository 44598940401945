import React from 'react';
import PropTypes from 'prop-types';

function AmountButton({ onClick, children }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="md:px-8 sm:px-7 px-4 md:py-4 py-3 flex md:gap-4 gap-2 border-2 border-ash rounded-md md:text-5xl text-2xl font-semibold text-ash cursor-pointer"
    >
      <span className="md:text-lg text-sm">$</span> {children}
    </button>
  );
}

AmountButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string
};

export default AmountButton;
