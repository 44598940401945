import React from 'react';
import { OurServicesHeroSection, OurServicesList } from 'components/layout/Services';

function ServicesView() {
  return (
    <div className="">
      <OurServicesHeroSection />
      <OurServicesList />
    </div>
  );
}

export default ServicesView;
