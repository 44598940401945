import {
  branding_img,
  hr_consulting_img,
  product_design_img,
  software_dev_img
} from 'assets/images';
import ServiceItemCard from 'components/modules/cards/ServiceItemCard';
import { Button } from 'components/widgets/buttons';
import { CONNECT } from 'navigation/CONSTANTS';
import React from 'react';

function OurServicesList() {
  const items = services.map(({ title, btnLabel, texts, image, bgStyle = '' }, index) => (
    <ServiceItemCard
      key={index}
      custom_styles={(index + 1) % 2 === 0 ? ' md:flex-row-reverse ' : ''}
    >
      <div className="w-full md:w-1/2 h-98 relative">
        <div
          className={` w-full h-full  rounded-3xl hidden md:block bg-cover  ${bgStyle}`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
        <figure className="w-full ">
          <img src={image} className=" rounded-3xl  image-full object-contain m-auto md:hidden" />
        </figure>
      </div>
      <div className="w-full md:w-1/2 md:pl-4 flex flex-col ">
        <div className=" self-start">
          <h4 className="text-2xl md:text-4xl font-bold italic text-main  pb-4 absolute top-0 md:relative py-3">
            {index + 1}. {title}
          </h4>
        </div>
        {texts.map((pText, indx) => (
          <p key={indx} className="text-main text-justify text-base md:text-2xl font-normal my-2">
            {pText}
          </p>
        ))}

        <Button
          to={CONNECT}
          className="my-5 btn w-60 md:w-96 h-9 md:h-14  normal-case text-gold border border-gold bg-white hover:bg-gold hover:text-white hover:border-gold font-medium text-xs md:text-lg"
        >
          {btnLabel}
        </Button>
      </div>
    </ServiceItemCard>
  ));

  return (
    <div className="flex flex-col px-8 lg:px-40">
      <div className=" md:my-10">
        <h3 className="text-gold text-3xl md:text-5xl font-semibold md:font-bold text-left md:text-center my-5">
          We are good at what we do!
        </h3>
        <p className=" font-normal text-base md:text-2xl text-main text-left md:text-center">
          Do you want to develop a new software solution for your business or are struggling with
          improving your user experience on your website but don’t know where to start or your brand
          needs to be designed from scratch?
        </p>
      </div>
      {items}
    </div>
  );
}

const services = [
  {
    title: 'HR consulting',
    image: hr_consulting_img,
    texts: [
      ` Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officiat.`,
      `Amet minim mollit non de, HR consulting Amet minim mollit non deserunt ullamco est sit
              aliqua dolor do amet sint. Velit officiat.Amet minim mollit non deserunt ullamco est
              sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non`
    ],
    btnLabel: `Make More Enquires`,
    bgStyle: ` bg-right`
  },
  {
    title: 'Product Design',
    image: product_design_img,
    texts: [
      `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non de, HR consulting`,
      `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiat.`
    ],
    btnLabel: `Make More Enquires`,
    bgStyle: ` bg-center`
  },
  {
    title: 'Software Development',
    image: software_dev_img,
    texts: [
      ` Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officiat.`,
      `Amet minim mollit non de, HR consulting Amet minim mollit non deserunt ullamco est sit
              aliqua dolor do amet sint. Velit officiat.Amet minim mollit non deserunt ullamco est
              sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non`
    ],
    btnLabel: `Make More Enquires`
  },
  {
    title: 'Branding',
    image: branding_img,
    texts: [
      ` Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officiat.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
              Velit officiat.`,
      `Amet minim mollit non de, HR consulting Amet minim mollit non deserunt ullamco est sit
              aliqua dolor do amet sint. Velit officiat.Amet minim mollit non deserunt ullamco est
              sit aliqua dolor do amet sint. Velit officiat.Amet minim mollit non`
    ],
    btnLabel: `Make More Enquires`,
    bgStyle: ` bg-center`
  }
];

export default OurServicesList;
