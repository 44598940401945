import React, { useState, useEffect } from 'react';
import { VideosCard } from 'components/modules/cards';
import { VideosData } from 'components/data';

function VideosSection() {
  const [videosData, setVideosData] = useState([]);
  const [seeAllVideos, setSeeAllVideos] = useState(false);

  useEffect(() => {
    const data = VideosData;
    setVideosData([...data]);
  }, []);

  const handleEventClick = () => {
    console.log('see all clicked');
    setSeeAllVideos(!seeAllVideos);
  };

  const allVideos = videosData.map((videos) => {
    return (
      <VideosCard
        key={videos.id}
        image={videos.image}
        title={videos.title}
        description={videos.description}
        videoURL={videos.videoURL}
      />
    );
  });

  return (
    <div className="md:py-20 py-12 md:px-40 px-8">
      <div className="flex md:justify-between gap-28 items-center">
        <h2 className="md:text-6xl text-2xl text-main font-medium tracking-wide">
          Get Videos of our past events
        </h2>
        <button onClick={handleEventClick} className="text-gold font-bold w-32">
          {seeAllVideos ? 'Hide all' : 'See all'}
        </button>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 items-center justify-center mt-12 gap-4">
        {seeAllVideos
          ? allVideos
          : videosData
              .sort(() => Math.random() - Math.random())
              .slice(0, 3)
              .map((videos) => {
                return (
                  <VideosCard
                    key={videos.id}
                    image={videos.image}
                    title={videos.title}
                    description={videos.description}
                    videoURL={videos.videoURL}
                  />
                );
              })}
      </div>
    </div>
  );
}

export default VideosSection;
