import { TESTIMG1, TESTIMG2, TESTIMG3 } from 'assets/images';

const data = [
  {
    id: 1,
    image: TESTIMG1,
    name: 'Jerome Bell',
    title: 'Product Designer',
    content:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit.'
  },
  {
    id: 2,
    image: TESTIMG2,
    name: 'Jerome Bell',
    title: 'Product Designer',
    content:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit.'
  },
  {
    id: 3,
    image: TESTIMG3,
    name: 'Jerome Bell',
    title: 'Product Designer',
    content:
      'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Velit officia consequat duis enim velit mollit.'
  }
];
export default data;
