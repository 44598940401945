import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import DonationView from './DonationView';

export const DonationContainer = () => {
  return (
    <PageWrapper>
      <DonationView />
    </PageWrapper>
  );
};
