import React from 'react';
import PropTypes from 'prop-types';

function VideosCard({ image, title, description, videoURL }) {
  return (
    <div className="bg-[#DBF5E8] md:h-96 h-80 md:w-96 w-full shadow-md md:p-12 px-8 py-4 flex flex-col justify-between">
      <p className="text-main md:text-2xl text-xl">
        <img src={image} className="md:h-24 h-20 md:w-24 w-20" />
      </p>
      <div>
        <p className="text-main text-3xl font-medium h-7">{title}</p>
        <p className="text-main text-2xl mt-2">{description}</p>
      </div>
      <a href={videoURL} className="text-gold text-2xl font-normal cursor-pointer">
        Watch video
      </a>
    </div>
  );
}
VideosCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  videoURL: PropTypes.string
};

export default VideosCard;
