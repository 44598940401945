import PropTypes from 'prop-types';
import React from 'react';

const PressCardContainer = ({ children }) => (
  <div className="grid md:grid-cols-2 grid-cols-1 gap-8 justify-center items-center ">
    {children}
  </div>
);

PressCardContainer.propTypes = {
  children: PropTypes.any
};

export default PressCardContainer;
