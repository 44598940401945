import React from 'react';
import PropTypes from 'prop-types';

const OrganizationSection = ({ organizations }) => {
  return (
    <div className="p-4 justify-center bg-theme md:my-12 my-4 items-center md:flex md:px-20 lg:px-40 px-8">
      <div className="w-full p-4 md:w-1/2">
        <img src={organizations} alt="Foot " className="h-full" />
      </div>

      <div className="w-full md:w-1/2 md:ml-20">
        <h5 className="py-2 text-3xl font-bold">Organizations</h5>
        <p className="py-3  text-base font-normal sm:text-2xl text-justify">
          Among other organizations that he has founded include Pan African Leadership Center, Black
          Woman Can, Zumaridi Communications, and Global Pan Africanism Network; an organization
          which seeks to reunite all people of African descent, advocate for their rights and
          freedoms throughout the world with international presence in Ghana, Nigeria, Sierra Leone
          and in the United States.
        </p>
      </div>
    </div>
  );
};

OrganizationSection.propTypes = {
  organizations: PropTypes.string
};
export default OrganizationSection;
