import { calender } from 'assets/icons';
import { Button } from 'components/widgets/buttons';
import { facebook, twitter, youtube, instagram, linkedin, whatsappDark } from 'assets/icons';
import React from 'react';

function QuoteSection() {
  const socialButtons = [
    { id: 1, imageUrl: twitter, text: 'Twitter', path: 'https://twitter.com/DMwambonu' },
    {
      id: 2,
      imageUrl: youtube,
      text: 'Youtube',
      path: 'https://www.youtube.com/channel/UCjFdVEbi9lwnCf93yCvgqEA'
    },
    {
      id: 3,
      imageUrl: facebook,
      text: 'Facebook',
      path: 'https://www.facebook.com/KingMenelikIII'
    },
    {
      id: 4,
      imageUrl: linkedin,
      text: 'Linkedin',
      path: 'https://www.linkedin.com/in/daniel-mwambonu-king-menelik-iii91b185172/'
    },
    { id: 5, imageUrl: instagram, text: 'Instagram', path: 'https://instagram.com/danielmwambonu' },
    { id: 6, imageUrl: whatsappDark, text: 'Whatsapp', path: 'https://wa.me/254700614290' }
  ];
  return (
    <div className="md:pt-20 pt-8 md:text-start text-center ">
      <p className="text-gold font-bold text-5xl">Get a quote!</p>
      <p className="text-main font-normal text-2xl pt-4">
        Fill up the form and our team will get back to you within 24hours
      </p>
      <Button
        href="https://calendly.com/mwambonudaniel"
        className="btn bg-gold text-white gap-2 border-gold hover:bg-main hover:border-main mt-12 md:w-[80%] w-full"
      >
        <img src={calender} />
        Set up a meeting with Daniel
      </Button>
      <p className="pt-8 pb-2 text-main text-2xl">Connect via social media</p>
      <div className="grid grid-cols-3 md:gap-4 gap-2">
        {socialButtons.map((icon) => (
          <Button
            key={icon.id}
            href={icon.path}
            className="md:p-3 p-2 md:text-base text-sm rounded-md flex items-center justify-center gap-1 group bg-white border-2 border-ash hover:bg-gold  hover:border-gold text-main hover:text-white"
          >
            {<img src={icon.imageUrl} className="md:h-4 h-3 md:w-4 w-3 hover:fill-white" />}
            {icon.text}
          </Button>
        ))}
      </div>
    </div>
  );
}

export default QuoteSection;
