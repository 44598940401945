import React, { useState } from 'react';
import { logo, menu, close } from 'assets/images';
import { NavLink, Link } from 'react-router-dom';
import { DropdownButton } from 'components/widgets/buttons';
import { PrimaryDropdown } from 'components/modules/dropdowns';
import { HOME, BIOGRAPHY, MEDIA, SERVICES, EVENTS, CONNECT, DONATION } from 'navigation/CONSTANTS';

function NavBarLinks() {
  const [openNav, setOpenNav] = useState(false);
  const navLinkClassName = 'hover:text-gold block my-4 text-center';

  return (
    <div className="bg-main flex justify-between gap-8 md:px-40 px-8 py-4">
      <div className="my-auto cursor-pointer">
        <Link to={HOME}>
          <img src={logo} />
        </Link>
      </div>
      <div
        onClick={() => setOpenNav(!openNav)}
        className="absolute right-8 top-[4.2rem] cursor-pointer md:hidden py-4"
      >
        <img src={openNav ? close : menu} />
      </div>
      <div
        className={`md:flex md:justify-end md:gap-16 bg-main items-center sm:pb-0 pb-12 text-white transition-all duration-500 ease-in absolute md:static left-0 md:z-auto z-[-1] w-full  ${
          openNav ? ' top-[6.2rem]' : ' top-[-800px]'
        } `}
      >
        <NavLink to={HOME} className={({ isActive }) => (isActive ? 'text-gold' : '')}>
          <span className="hover:text-gold block md:border-b-0 border-b-2 border-b-gray-200 text-center md:py-0 py-8">
            Home
          </span>
        </NavLink>
        <NavLink to={BIOGRAPHY} className={({ isActive }) => (isActive ? 'text-gold' : '')}>
          <span className="hover:text-gold block md:my-0 mt-12 text-center">Biography</span>
        </NavLink>

        <PrimaryDropdown
          button={(isOpen) => (
            <DropdownButton isOpen={isOpen} className={navLinkClassName}>
              Media
            </DropdownButton>
          )}
        >
          <Link to={MEDIA.PRESS}>Press Release</Link>
          <Link to={MEDIA.VIDEOS}>Video Links</Link>
          <Link to={MEDIA.NEWS}>Featured News</Link>
          <Link to={MEDIA.ORGANIZATION}>Organization</Link>
        </PrimaryDropdown>

        <PrimaryDropdown
          button={(isOpen) => (
            <DropdownButton className={navLinkClassName} isOpen={isOpen}>
              Services
            </DropdownButton>
          )}
        >
          <Link to={SERVICES.PARTNER}>Partner with us</Link>
          <Link to={SERVICES.SERVICES}>Our Services</Link>
          <Link to={SERVICES.REQUIREMENTS}>Service Requirements</Link>
        </PrimaryDropdown>

        <PrimaryDropdown
          button={(isOpen) => (
            <DropdownButton isOpen={isOpen} className={navLinkClassName}>
              Events
            </DropdownButton>
          )}
        >
          <Link to={EVENTS.LIVE}>Live Events</Link>
          <Link to={EVENTS.PAST}>Past Events</Link>
          <Link to={EVENTS.UPCOMING}>Upcoming Events</Link>
        </PrimaryDropdown>

        <NavLink to={CONNECT} className={({ isActive }) => (isActive ? 'text-gold' : '')}>
          <span className="hover:text-gold block pr-0 md:my-0 my-12 text-center">Connect</span>
        </NavLink>
        <NavLink
          to={DONATION}
          className={({ isActive }) =>
            isActive
              ? 'text-gold bg-main text-center block rounded-md md:py-4 py-4'
              : 'text-base text-white bg-gold md:py-4 py-4 rounded-md block text-center'
          }
        >
          <span className=" px-12  cursor-pointer">Donate</span>
        </NavLink>
      </div>
    </div>
  );
}

export default NavBarLinks;
