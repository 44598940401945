import React from 'react';
import PropTypes from 'prop-types';

const VissionSection = ({ children }) => {
  return (
    <div className="md:px-20 lg:px-40 px-8 py-6">
      <h2 className="text-3xl font-bold sm:text-4xl py-4">Daniel’s Vision for Unified Africa:</h2>
      {children}
    </div>
  );
};

VissionSection.propTypes = {
  children: PropTypes.any
};
export default VissionSection;
