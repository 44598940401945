import React from 'react';
import UpcomingEventsView from './UpcomingEventsView';

export const UpcomingEventsContainer = () => {
  return (
    <div>
      <UpcomingEventsView />
    </div>
  );
};
