import React from 'react';
import { privacyHeader } from 'assets/images';

function HeroSection() {
  return (
    <div className="w-full" style={{ background: `url(${privacyHeader})` }}>
      <div className="flex flex-col w-full md:h-96 h-48 items-center justify-center md:px-40 px-8">
        <h1 className="text-gold md:text-6xl text-4xl font-extrabold text-center mb-4">
          Privacy Policy
        </h1>
        <p className="md:text-2xl text-lg text-white font-medium text-center">Privacy policy</p>
      </div>
    </div>
  );
}

export default HeroSection;
