import { configureStore } from '@reduxjs/toolkit';
import { api } from 'services/api';

import messageReducer from 'slices/message';
const reducer = {
  [api.reducerPath]: api.reducer,
  message: messageReducer
};
const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production'
});
export default store;
