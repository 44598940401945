import React from 'react';
import Context from './Context';

function Introduction() {
  return (
    <div className=" flex md:flex-row flex-col md:gap-8 gap-4 md:px-40 px-8 mb-12">
      <div className="w-full md:w-1/2 mt-10 mb-4 md:my-3 mx-2">
        <h3 className="font-normal md:text-5xl text-4xl text-gold py-3">Partner With US</h3>
        <p className="font-normal md:text-xl text-lg text-main md:mb-10 mb-4 text-justify">
          Let’s get in touch! We love being where the industry goes to give back. Maybe you want to
          offer donated media, become an Ad Council board member, share your company’s creative
          expertise or collaborate on a content partnership.
        </p>
        <p className="font-normal md:text-xl text-lg text-main md:mb-10 mb-4 text-justify">
          There are so many ways we can work together to create exponential social impact.
        </p>
        <p className="font-normal md:text-xl text-lg text-main md:mb-10 mb-4 text-justify">
          If you would like to partner on a new campaign, please review our campaign criteria here.
        </p>
        <p className="font-normal md:text-xl text-lg text-main md:mb-10 mb-4 text-justify">
          If you or your organization are passionate about making a measurable difference in
          society, we’d love to partner with you.
        </p>
      </div>
      <div>
        <Context />
      </div>
    </div>
  );
}

export default Introduction;
