import React from 'react';
import { hr_consulting_img } from 'assets/images';
import ServicesHeroCard from 'components/modules/cards/ServicesHeroCard';

function HrConsultingHeroSection() {
  return (
    <ServicesHeroCard image={hr_consulting_img} title={'Our Services'} subTitle={'HR Consulting'} />
  );
}

export default HrConsultingHeroSection;
