import React from 'react';

const PanAfricanism = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold  sm:font-bold ">
        Pan Africanism and Self Reliance
      </h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He is the founder and Director of <span className="text-gold">Zumaridi Communications</span>
        , a Nairobi based Technology firm focused on harnessing the power of technology to{' '}
        <span className="text-gold">
          create social and economic impact and uplift people of African descent
        </span>
        .
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He is using technology as a conduit to unify Africa and bring people together.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        {' '}
        <span className=""></span>Among the technology initiatives he has initiated include
        <span className="text-gold">
          {' '}
          developing a live streaming and video conferencing platform with natural language
          processing capabilities
        </span>{' '}
        to enable translation into indigenous African languages and vice versa.
      </p>
    </div>
  );
};

export default PanAfricanism;
