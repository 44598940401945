import React, { memo } from 'react';
import { Form, Formik } from 'formik';
import { subscribeSchema } from 'validations';

function SubscribeForm() {
  const formikInitialValues = {
    email: ''
  };

  const handleSubmit = (values) => {
    console.log(values);
  };

  return (
    <div className="py-12 md:px-40 px-8">
      <div className="flex flex-col items-center">
        <h2 className="text-main md:text-4xl text-2xl text-center font-extrabold">
          Newsletter Subscription
        </h2>
        <p className="text-main text-xl text-center md:mx-44 opacity-50 font-semibold my-6">
          Join Daniel to stay updated on the latest news of how he’s impacting change for the
          continent of Africa and its beautiful people.
        </p>
      </div>

      <Formik
        onSubmit={handleSubmit}
        validate={subscribeSchema}
        initialValues={formikInitialValues}
        enableReinitialize={true}
      >
        <Form className="grid md:grid-cols-2 grid-cols-1 bg-green rounded-lg w-full  md:gap-24 gap-4 md:p-16 px-4 py-12">
          <div className="text-main md:my-auto mb-4 md:mx-auto w-full">
            <p className="text-white md:text-3xl text-4xl leading-10 font-semibold md:mb-0 mb-2">
              Stay Connected
            </p>
            <p className="text-white md:text-lg text-3xl leading-10 mt-1">
              Subscribe to receive the latest news and updates.
            </p>
          </div>
          <div className="flex md:m-auto w-full bg-white rounded items-center justify-between p-1">
            <input
              type="email"
              name="email"
              placeholder="Enter email address"
              className=" focus:ring-0 border-none border-white text-main w-full h-full"
            />
            <div className="">
              <button
                type="submit"
                className="bg-main hover:bg-gold text-white rounded h-14 sm:w-32 w-28"
              >
                continue
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default memo(SubscribeForm);
