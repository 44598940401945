import {
  usafPdf,
  ahmLaunch,
  ahmOfficial,
  usafCongrats,
  usafQueens,
  gpanCondemns,
  gpanInvitation
} from 'assets/images';
import {
  AHMPROCLAMATION,
  AHMSpeech,
  BiddenHarrisPress,
  BMHMJusticeForKenion,
  GhanaHQALD58,
  GHANAHQPAP,
  USAFAzania,
  USAFQUEENELIZABETHDEATH
} from 'assets/pdf';
const data = [
  {
    id: 1,
    imageURL: usafPdf,
    title: 'How to do main text!',
    content:
      'The united states of Africa is disturbed by heartrending videos from South Africa and strongly condemns the phoenix massac... See more',
    pdfUrl: BMHMJusticeForKenion
  },
  {
    id: 2,
    imageURL: ahmLaunch,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMSpeech
  },
  {
    id: 3,
    imageURL: usafQueens,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFQUEENELIZABETHDEATH
  },
  {
    id: 4,
    imageURL: gpanCondemns,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GHANAHQPAP
  },
  {
    id: 5,
    imageURL: ahmOfficial,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMPROCLAMATION
  },
  {
    id: 6,
    imageURL: usafCongrats,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: BiddenHarrisPress
  },
  {
    id: 7,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GhanaHQALD58
  },
  {
    id: 8,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFAzania
  },
  {
    id: 9,
    imageURL: usafPdf,
    title: 'How to do main text!',
    content:
      'The united states of Africa is disturbed by heartrending videos from South Africa and strongly condemns the phoenix massac... See more',
    pdfUrl: BMHMJusticeForKenion
  },
  {
    id: 10,
    imageURL: ahmLaunch,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMSpeech
  },
  {
    id: 11,
    imageURL: usafQueens,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFQUEENELIZABETHDEATH
  },
  {
    id: 12,
    imageURL: gpanCondemns,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GHANAHQPAP
  },
  {
    id: 13,
    imageURL: ahmOfficial,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMPROCLAMATION
  },
  {
    id: 14,
    imageURL: usafCongrats,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: BiddenHarrisPress
  },
  {
    id: 15,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GhanaHQALD58
  },
  {
    id: 16,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFAzania
  },
  {
    id: 17,
    imageURL: usafPdf,
    title: 'How to do main text!',
    content:
      'The united states of Africa is disturbed by heartrending videos from South Africa and strongly condemns the phoenix massac... See more',
    pdfUrl: BMHMJusticeForKenion
  },
  {
    id: 18,
    imageURL: ahmLaunch,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMSpeech
  },
  {
    id: 19,
    imageURL: usafQueens,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFQUEENELIZABETHDEATH
  },
  {
    id: 20,
    imageURL: gpanCondemns,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GHANAHQPAP
  },
  {
    id: 21,
    imageURL: ahmOfficial,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: AHMPROCLAMATION
  },
  {
    id: 22,
    imageURL: usafCongrats,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: BiddenHarrisPress
  },
  {
    id: 23,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: GhanaHQALD58
  },
  {
    id: 24,
    imageURL: gpanInvitation,
    title: 'How to do main text!',
    content:
      'All the Articles and content for the pdf goes her All the Articles and content for the pdf goes her... See more',
    pdfUrl: USAFAzania
  }
];
export default data;
