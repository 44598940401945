import React from 'react';

const HumanRights = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold sm:font-bold ">
        Human Rights and Indigenous People Representative
      </h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        His most notable Human Rights campaign include{' '}
        <span className="text-gold">AfrikaForWestPapua</span> and{' '}
        <span className="text-gold">Stand With West Papua campaign</span> which has exposed the
        black genocide in West Papua and brought to international limelight systematic human rights
        violations Indonesia has been perpetrating against Indigenous people.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3 pr-4">
        As a result of a sustained campaign since 2017 , this has drawn more Africans to support the
        <span className="text-gold">
          quest for self-determination for the black people in West Papua
        </span>
        . His journey to becoming an activist began in 2017 when he learnt about the{' '}
        <span className="text-gold">atrocities Indonesia</span> is committing against the indigenous
        black people in the island of New Guinea.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He founded the AfrikaForWestPapua campagin to raise awareness on the genocide and these
        efforts have continued to give voice to the voiceless. Daniel is the{' '}
        <span className="text-gold">international representative for Free West Papua in Kenya</span>{' '}
        since his appointment in April 2020 by H.E Benny Wenda the Chairperson of{' '}
        <span className="text-gold">United Liberation Movement for West Papua</span> (ULMWP).
      </p>
    </div>
  );
};

export default HumanRights;
