import React from 'react';

const PrivacySection = () => {
  return (
    <div className="md:px-40 px-8 flex flex-col md:gap-16 gap-8 text-main md:py-12 py-8">
      <p className="text-3xl md:text-5xl font-bold">Privacy Policy</p>
      <div className="flex flex-col gap-4">
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          Welcome to the personal website of Daniel Mwambonu, President of United States of Africa.
          At United States of Africa, we take your privacy seriously and are committed to protecting
          your personal information.
        </p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          This privacy policy outlines the types of personal information we collect, how we use and
          share that information, and the choices you have regarding your personal information.
          Please read this policy carefully before using our website or submitting any personal
          information.
        </p>
      </div>

      <div className="flex flex-col md:gap-12 gap-8">
        <p className="text-xl md:text-3xl font-bold">Information We Collect</p>

        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          We may collect the following types of personal information from you when you use our
          website:
        </p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          Contact information, such as your name, email address, and phone number Demographic
          information, such as your age, gender, and location Any other personal information that
          you choose to provide to us How We Use Your Personal Information
        </p>
        <div className="flex flex-col md:gap-8 gap-4">
          <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
            We use the personal information we collect from you for the following purposes:
          </p>

          <ul className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10 list-disc pl-8">
            <li>To communicate with you and respond to your inquiries</li>
            <li>To improve our website and the services we offer</li>
            <li>
              To understand your needs and preferences, and to personalize your experience on our
              website Sharing Your Personal Information{' '}
            </li>
          </ul>
        </div>

        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          We do not sell or rent your personal information to third parties. We may share your
          personal information with third parties in the following circumstances:
        </p>

        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          With your consent
        </p>
        <ul className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10 list-disc pl-8">
          <li>To fulfill a request or transaction you have initiated</li>
          <li>To comply with legal requirements or a court order</li>
          <li>To protect the rights, property, or safety of USAF, our employees, or our users</li>
        </ul>
      </div>

      <div className="flex flex-col md:gap-16 gap-8">
        <p className="text-xl md:text-3xl font-bold">Your Choices</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          You have the following choices regarding your personal information:
        </p>

        <div className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10 flex flex-col gap-4">
          <p>
            You can choose not to provide personal information to us. However, this may limit your
            ability to use certain features of our website.
          </p>
          <p>
            You can update, correct, or delete your personal information at any time by contacting
            us. You can opt out of receiving communications from us by following the unsubscribe
            instructions in any email we send you.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <p className="text-xl md:text-3xl font-bold">Changes To This Privacy Policy</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          We may update this privacy policy from time to time. We will post any updates on this page
          and encourage you to review this policy periodically.
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <p className=" text-xl md:text-3xl font-bold">Contact Us</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          If you have any questions or concerns about our privacy policy or the personal information
          we have collected about you, please contact us at{' '}
          <span className="text-gold">
            <a href="mailto:info@danielmwambonu.com"> contact@danielmwambonu.com</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacySection;
