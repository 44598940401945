import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/widgets/buttons';
import { calender } from 'assets/icons';

const HeroSection = ({ background, daniel, danielSm }) => {
  return (
    <div>
      <div className="md:flex w-full pt-4 md:pt-0 md:pl-20 lg:pl-40 px-8 md:pr-0 ">
        <div className="flex flex-col items-center justify-center md:w-1/2 md:pr-32">
          <div className="w-full  ">
            <h1 className="text-gold pb-3 uppercase text-center md:capitalize text-5xl sm:text-4xl  lg:text-5xl lg:tracking-wider font-extrabold sm:font-bold md:text-justify">
              Daniel Mwambonu<span className=" invisible md:visible">,</span>
            </h1>

            <p className=" text-base font-normal sm:font-medium lg:text-2xl  w-full text-center lg:tracking-wider  md:text-left">
              an African International Human Rights
            </p>
            <p className="text-base font-normal sm:font-medium lg:text-2xl  w-full text-center lg:tracking-wider  md:text-left">
              Activist,
              <span className="text-green"> Pan Africanist, African Nationalist </span>
            </p>
            <p className="text-base  font-normal sm:font-medium lg:text-2xl w-full text-center lg:tracking-wider  md:text-left">
              and Mental Health Advocate.
            </p>
            <div className="md:mt-8 mt-4 w-full flex">
              <Button
                href="https://calendly.com/mwambonudaniel"
                className="btn bg-gold text-white gap-2 border-gold hover:bg-main hover:border-main mt-12 md:w-[80%] w-full"
              >
                <img src={calender} />
                Set up a meeting with Daniel
              </Button>
            </div>
          </div>
        </div>

        <div
          className="w-full md:w-1/2 my-5 sm:mt-0 flex items-center justify-center  bg-fixed bg-main bg-opacity-90"
          style={{ backgroundImage: `url(${background})` }}
        >
          <img
            src={danielSm}
            className=" h-full object-cover pt-10  sm:hidden"
            alt="Daniel's Image"
          />
          <img
            src={daniel}
            className=" h-full pt-5 object-cover hidden sm:block"
            alt="Daniel's Image"
          />
        </div>
      </div>

      {/* Who's Daniel */}
      <div className="py-4 my-4 md:px-20 lg:px-40 px-8">
        <h2 className="text-[1.4rem] font-bold sm:text-3xl">Who is Daniel Mwambonu ?</h2>
        <p className="text-base font-normal sm:text-2xl text-justify py-3">
          Daniel is a renowned Pan Africanist, Human Rights Activist, Executive Producer, Mental
          Health Advocate and a technophile turned self taught HR Professional with experience in
          Technical Recruiting and Talents Acquisition.
        </p>
        <p className="text-base font-normal sm:text-2xl text-justify py-3">
          He has been a fierce advocate for{' '}
          <span className="text-gold">
            Indigenous Black people in West Papua in South Pacific and Melanesia
          </span>{' '}
          in their quest for self determination from Indonesian Colonialism.
        </p>
        <p className="text-base font-normal sm:text-2xl text-justify py-3">
          He is the President of United States of Africa, a non profit corporation registered in
          Texas, United States and also the{' '}
          <span className="text-gold">CEO of Black Mental Health Matters Inc</span>, a non profit
          which runs a weekly show to{' '}
          <span className="text-gold">raise awareness on Mental Health </span>and{' '}
          <span className="text-gold">eradicate stigma among Black Communities worldwide</span>.
        </p>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  daniel: PropTypes.string,
  danielSm: PropTypes.string,
  background: PropTypes.string
};

export default HeroSection;
