import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { InputFormGroup, SelectFormGroup, TextareaFormGroup } from 'components/modules/forms';
import { Button } from 'components/widgets/buttons';
import { envelope, person } from 'assets/icons';
import { connectSchema } from 'validations';
import { ConnectContext } from './Context';

function ConnectForm() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(ConnectContext);
  const formikInitialValues = {
    fullName: '',
    email: '',
    reason: '',
    message: ''
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  return (
    <div className="md:w-[627px] w-full md:h-[856px] h-[840px]  shadow-lg md:p-8 p-2">
      <Formik
        initialValues={formikInitialValues}
        validate={connectSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => {
          return (
            <div>
              <Form>
                <div className="flex items-end my-4">
                  <InputFormGroup
                    type="text"
                    nameAndId="fullName"
                    label="Full name"
                    placeholder="Jane Doe."
                    iconUrl={person}
                  />
                </div>
                <div className="flex  my-4">
                  <InputFormGroup
                    type="email"
                    nameAndId="email"
                    label="Email"
                    placeholder="janedoe@email.com"
                    iconUrl={envelope}
                  />
                </div>
                <SelectFormGroup
                  label="Choose Topic"
                  name="reason"
                  nameAndId="reason"
                  className="my-4"
                >
                  <option value="">Select a reason</option>
                  <option value="meeting">General meeting</option>
                  <option value="collaboration">Collaboration</option>
                  <option value="partnerships">Partnerships</option>
                  <option value="other">Other</option>
                </SelectFormGroup>
                <TextareaFormGroup
                  nameAndId="message"
                  label="Message"
                  placeholder="Hello."
                  autoResize={false}
                  className="bg-theme border-theme rounded-md grow max-h-30vh h-25vh placeholder-ash text-main"
                />
                <div className=" md:mt-20 mt-12">
                  <Button
                    type="submit"
                    isSubmitting={isSubmitting}
                    className="w-full bg-gold rounded-md text-white border-gold hover:bg-main hover:border-main py-3"
                  >
                    Send message
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default ConnectForm;
