import React, { useState, useEffect } from 'react';
import { NewsCard } from 'components/modules/cards';
import { NewsData } from 'components/data';

function CardSection() {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const data = NewsData;
    setNewsData(data);
  }, []);

  return (
    <div className="grid md:grid-cols-3 grid-cols-1 md:gap-28 gap-8 my-12 md:px-40 px-8">
      {newsData.slice(0, 6).map((news) => {
        return (
          <NewsCard
            key={news.id}
            date={news.date}
            views={news.views}
            readTime={news.readTime}
            content={news.content}
            newsLink={news.newsLink}
          />
        );
      })}
    </div>
  );
}

export default CardSection;
