import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function AmountInput({ nameAndId, placeholder, type, className }) {
  return (
    <div className={`form-control ${className || 'w-full'}`}>
      <Field
        name={nameAndId}
        id={nameAndId}
        as={'input'}
        placeholder={placeholder}
        type={type || 'text'}
        className={
          className ||
          'bg-white md:w-full w-[98%] rounded-r-md placeholder-ash text-main px-6 md:py-6 py-3 border-2 border-ash rounded-md md:text-4xl text-2xl'
        }
      />

      <ErrorMessage
        component="label"
        name={nameAndId}
        className="label-text-alt w-full text-alerts-error-color"
      />
    </div>
  );
}
AmountInput.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string
};

export default AmountInput;
