import { videoIcon } from 'assets/images';

const data = [
  {
    id: 1,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  },
  {
    id: 2,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  },
  {
    id: 3,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  },
  {
    id: 4,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  },
  {
    id: 5,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  },
  {
    id: 6,
    image: videoIcon,
    title: 'Title goes here',
    description: 'sub text',
    vidURL: '/'
  }
];
export default data;
