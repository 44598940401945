import React from 'react';

const OfficialHolidays = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold sm:font-bold ">Official Holidays</h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        As the President of the United States of Africa, Daniel Mwambonu has established two
        continental holidays after realizing that Africa as a continent almost had no holidays of
        her own.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        <span className="text-gold">Through a Presidential Proclamation</span> he brought to
        existence the <span className="text-gold">African History Month in 2021</span> an annual
        holiday which was first commemorated on 1st March to{' '}
        <span className="text-gold">
          31st March to celebrate the achievements of people of African descent
        </span>{' '}
        and acknowledge the enormous contributions African people have made to world history.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He also established the{' '}
        <span className="text-gold">
          African Business Month in November 2020 from 1st November to 30th November
        </span>{' '}
        to focus on promoting African businesses in Africa, Europe, Caribbean, Pacific and round the
        world.
      </p>
    </div>
  );
};

export default OfficialHolidays;
