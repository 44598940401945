import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PropTypes from 'prop-types';
import { CarouselArrowButtonRight, CarouselArrowButtonLeft } from 'components/widgets/buttons';

function MultiCarousel(props) {
  const { children } = props;
  const responsive = {
    extraLargedesktop: {
      breakpoint: { max: 1536, min: 1280 },
      slidesToSlide: 1,
      items: 3
    },
    desktop: {
      breakpoint: { max: 1280, min: 1024 },
      slidesToSlide: 1,
      items: 3
    },
    laptop: {
      breakpoint: { max: 1024, min: 768 },
      slidesToSlide: 1,
      items: 3
    },
    tablet: {
      breakpoint: { max: 768, min: 596 },
      slidesToSlide: 1,
      items: 2
    },
    mobile: {
      breakpoint: { max: 596, min: 0 },
      slidesToSlide: 1,
      items: 1
    }
  };

  return (
    <Carousel
      swipeable={true}
      draggable={true}
      autoPlay={true}
      rewind={true}
      autoPlaySpeed={5000}
      infinite
      responsive={responsive}
      keyBoardControl={true}
      containerClass="carousel-container pt-4 pb-8"
      deviceType={props.deviceType}
      itemClass=""
      customTransition="transform ease-in-out .6s .2s"
      customRightArrow={<CarouselArrowButtonRight />}
      customLeftArrow={<CarouselArrowButtonLeft />}
    >
      {children}
    </Carousel>
  );
}

MultiCarousel.propTypes = {
  children: PropTypes.any.isRequired,
  deviceType: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
};

export default MultiCarousel;
