import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function InputFormNoIcon({ nameAndId, placeholder, label, type, className, labelClassName }) {
  return (
    <div className={`form-control ${className || 'w-full'}`}>
      <label className={`label ${labelClassName}`} htmlFor={nameAndId}>
        <span className="label-text text-main">{label}</span>
      </label>

      <Field
        name={nameAndId}
        id={nameAndId}
        as={'input'}
        placeholder={placeholder}
        type={type || 'text'}
        className={className || 'bg-theme border-theme w-full rounded-md placeholder-ash text-main'}
      />

      <ErrorMessage
        component="label"
        name={nameAndId}
        className="label-text-alt w-full text-alerts-error-color"
      />
    </div>
  );
}
InputFormNoIcon.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['text', 'number', 'email']),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string
};

export default InputFormNoIcon;
