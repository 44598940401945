import React, { useState, useEffect } from 'react';
import { OrganizationCard } from 'components/modules/cards';
import { OrganizationData } from 'components/data';

function CardSection() {
  const [cardData, setCardData] = useState([]);
  useEffect(() => {
    const data = OrganizationData;
    setCardData([...data]);
  }, []);

  return (
    <div className="grid md:grid-cols-2 grid-cols-1 md:py-20 py-12 md:px-40 px-4 gap-6">
      {cardData.map((item) => {
        return (
          <OrganizationCard
            key={item.id}
            image={item.image}
            title={item.title}
            content={item.content}
            btnUrl={item.btnUrl}
          />
        );
      })}
    </div>
  );
}

export default CardSection;
