import React from 'react';
import { subIMG } from 'assets/images';

const MissionSection = () => {
  return (
    <div className="w-full relative">
      <figure>
        <img src={subIMG} className="w-full object-cover h-80" />
      </figure>
      <div className="absolute top-1/2 transform -translate-y-1/2 md:px-20 px-10">
        <p className="md:text-5xl text-[1.5rem] md:leading-[3rem] text-white font-semibold">
          Our mission is to empower every <br />
          African with the freedom to move!
        </p>
        <a
          href="/donate"
          className="md:text-4xl text-3xl text-gold font-bold underline underline-offset-8"
        >
          Donate Now
        </a>
      </div>
    </div>
  );
};

export default MissionSection;
