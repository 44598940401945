import { HeroSection, TermsSection } from 'components/layout/Terms';
import React from 'react';

const TermsView = () => {
  return (
    <div>
      <HeroSection />
      <TermsSection />
    </div>
  );
};

export default TermsView;
