import React from 'react';
import PropTypes from 'prop-types';
import { arrowRight } from 'assets/icons';

function CarouselArrowButtonRight() {
  return (
    <div className=" hover:translate-x-1 transition-all">
      <button className="" onClick={() => onclick()}>
        <img src={arrowRight} />
      </button>
    </div>
  );
}

CarouselArrowButtonRight.propTypes = {
  onClick: PropTypes.func
};

export default CarouselArrowButtonRight;
