import React from 'react';
import { HeroSection, RequestSection } from './components';

function DonationView() {
  return (
    <div>
      <HeroSection />
      <RequestSection />
    </div>
  );
}

export default DonationView;
