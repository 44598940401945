import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

function PrimaryDropdown({ children, button }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownElements = useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    const className =
      'btn last:border-b-0 border-0 border-b border-gold btn-ghost hover:bg-gold w-full hover:text-white transition-colors rounded-none whitespace:normal sm:whitespace-nowrap';

    return childrenArray.map((child) => {
      return React.cloneElement(child, { ...child.props, className });
    });
  }, [children]);

  const toogleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="group min-w-max relative">
      <span onClick={toogleDropdown}>{button(isOpen)}</span>
      {isOpen ? (
        <div className="min-w-full md:absolute  transition-transform duration-1000">
          <div className="border border-gold btn-group btn-group-vertical bg-white text-main overflow-hidden">
            {dropdownElements}
          </div>
        </div>
      ) : (
        <div className="min-w-full hidden md:absolute group-hover:md:block transition-transform duration-1000">
          <div className="border border-gold btn-group btn-group-vertical bg-white text-main overflow-hidden">
            {dropdownElements}
          </div>
        </div>
      )}
    </div>
  );
}

PrimaryDropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  button: PropTypes.any.isRequired,
  isOpen: PropTypes.bool
};

export default PrimaryDropdown;
