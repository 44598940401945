import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';

function TextareaFormGroup({ nameAndId, placeholder, label, disabled, className, autoResize }) {
  const textareaRef = useRef();

  useEffect(() => {
    const textarea = textareaRef.current;

    if (autoResize && textarea) {
      textarea.style.height = 'initial';
      textarea.style.height = textarea.scrollHeight + 10 + 'px';
    }
  }, [autoResize, textareaRef.current?.value]);
  return (
    <div className="form-control my-2 w-full h-full">
      <label className="label" htmlFor={nameAndId}>
        <span className="label-text text-main">{label}</span>
      </label>
      <Field
        name={nameAndId}
        id={nameAndId}
        as="textarea"
        placeholder={placeholder || ''}
        className={
          className ||
          ` border-theme focus:border-gold bg-theme max-h-30vh h-full placeholder-ash text-main`
        }
        disabled={disabled || false}
        innerRef={textareaRef}
      />
      <ErrorMessage
        component="label"
        name={nameAndId}
        className="label-text-alt w-full text-alerts-error-color"
      />
    </div>
  );
}
TextareaFormGroup.propTypes = {
  nameAndId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  autoResize: PropTypes.bool
};

TextareaFormGroup.defaultProps = {
  autoResize: true
};

export default TextareaFormGroup;
