import React from 'react';
import PressView from './PressView';

export const PressContainer = () => {
  return (
    <div>
      <PressView />
    </div>
  );
};
