import React from 'react';
import PropTypes from 'prop-types';
import { envelope, close } from 'assets/icons';

function PdfModal({ setOpenModal }) {
  return (
    <div className="fixed inset-0 bg-[#000] bg-opacity-70 flex items-center justify-center px-1">
      <div className="md:w-[750px] w-[90%] md:h-[480px] h-[326px] pb-4 bg-[#fff]">
        <div className="flex bg-gold text-white justify-between items-center">
          <div className="bg-gold h-12 md:pl-28 pl-12 py-2 pr-2">
            <p className="font-semibold text-white md:text-2xl text-lg">Download FREE!</p>
          </div>
          <div className=" font-bold pr-8">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <img src={close} alt="close icon" />
            </button>
          </div>
        </div>
        <div className="flex md:flex-row flex-col items-center md:px-8 px-2">
          <div className="bg-ash h-60 w-96 md:block hidden "></div>
          <div className="px-8 py-20">
            <form>
              <p className="text-main md:text-2xl text-sm">
                To downoload <span className="font-bold">Pdf Name Free..</span> today just enter
                your email address!
              </p>
              <div className="flex items-center justify-center py-4">
                <div className="bg-ash md:h-16 h-8 md:w-16 w-8 flex items-center justify-center rounded-l-lg ">
                  <img src={envelope} className="md:h-8 h-4 md:w-8 w-4" />
                </div>
                <input
                  type="email"
                  placeholder="example@gmail.com"
                  className="md:h-16 h-8 w-full border-0 bg-theme rounded-r-lg"
                />
              </div>
              <input
                type="submit"
                value="Submit & Download"
                className="bg-gold text-white md:py-3 py-1 w-full rounded-md hover:bg-main"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
PdfModal.propTypes = {
  setOpenModal: PropTypes.bool
};

export default PdfModal;
