import React, { useContext } from 'react';
import { TextareaFormGroup } from 'components/modules/forms';
import { Form, Formik } from 'formik';
import { PartnerContext } from './Context';
import { arrowLeft } from 'assets/icons';

function InformationForm() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(PartnerContext);
  const formikInitialValues = {
    description: ''
  };
  const validateDescription = (values) => {
    let errors = {};
    if (!values.description) {
      errors.description = 'description is required';
    } else if (values.description.length < 10) {
      errors.description = 'Invalid message. Message must be at least 10 characters';
    } else if (!/[A-Za-z0-9]+/i.test(values.description)) {
      errors.description = 'Invalid message';
    }
    return errors;
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
    console.log('FInal Data', { ...data });
  };
  return (
    <div className="shadow-md">
      <Formik
        initialValues={formikInitialValues}
        validate={validateDescription}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form className="md:p-8 p-4 md:h-[700px] h-full flex flex-col md:gap-8 gap-4">
          <TextareaFormGroup
            nameAndId="description"
            label="description"
            className="bg-theme border-theme rounded-md grow max-h-40vh md:h-[35vh] h-[200px] placeholder-ash text-main"
          />
          <div className="pt-20 flex md:gap-4 gap-2">
            <button
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              className="bg-white text-main border-2 flex items-center justify-center md:gap-2 gap-1 border-main hover:border-gold rounded-md md:px-4 px-4 md:py-2 w-[30%] hover:bg-gold hover:text-white"
            >
              <img src={arrowLeft} /> Back
            </button>
            <button
              type="submit"
              className="bg-gold px-8 md:py-2 flex items-center justify-center md:gap-2 gap-1 text-white rounded-md border-2 border-gold hover:border-main w-[70%] hover:bg-main"
            >
              Partner now
            </button>
          </div>
          <p className="text-gold underline underline-offset-4 font-normal md:text-xl text-xs text-center md:mt-28 mt-4">
            Powered by Daniel
          </p>
        </Form>
      </Formik>
    </div>
  );
}

export default InformationForm;
