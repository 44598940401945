import React from 'react';

const UnitedAfrica = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold  sm:font-bold ">Vision for Unified Africa</h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        His vision for establishing the United States of Africa Federal Government, is to unify
        Africa, the Caribbean, and Pacific states and{' '}
        <span className="text-gold">reunite all people of African</span> descent under one political
        and economic leadership has continued to{' '}
        <span className="text-gold">inspire young Pan Africanists around the world</span>. He is an
        undisputed defender of the rights of Africa to be sovereign and a staunch.
      </p>
    </div>
  );
};

export default UnitedAfrica;
