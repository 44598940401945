import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Biography,
  ErrorPage,
  Home,
  Press,
  Videos,
  News,
  Organization,
  Events,
  Connect,
  Donation,
  Partner,
  Requirements,
  PastEvents,
  UpcomingEvents,
  Services,
  Privacy,
  Terms
} from 'pages';
import {
  BIOGRAPHY,
  HOME,
  MEDIA,
  SERVICES,
  EVENTS,
  CONNECT,
  DONATION,
  PRIVACY,
  TERMS
} from './CONSTANTS';

function RouterConfig() {
  return (
    <div>
      <Routes>
        {/*************************PUBLIC ROUTES************************************** */}

        {/* List all public routes here */}
        <Route exact path={HOME} element={<Home />} />
        <Route exact path={BIOGRAPHY} element={<Biography />} />

        <Route path={MEDIA.PRESS} element={<Press />} />
        <Route path={MEDIA.VIDEOS} element={<Videos />} />
        <Route path={MEDIA.NEWS} element={<News />} />
        <Route path={MEDIA.ORGANIZATION} element={<Organization />} />

        <Route exact path={SERVICES.PARTNER} element={<Partner />} />
        <Route exact path={SERVICES.SERVICES} element={<Services />} />
        <Route exact path={SERVICES.REQUIREMENTS} element={<Requirements />} />

        <Route path={EVENTS.LIVE} element={<Events />} />
        <Route path={EVENTS.PAST} element={<PastEvents />} />
        <Route path={EVENTS.UPCOMING} element={<UpcomingEvents />} />

        <Route exact path={CONNECT} element={<Connect />} />
        <Route exact path={DONATION} element={<Donation />} />
        <Route exact path={PRIVACY} element={<Privacy />} />
        <Route exact path={TERMS} element={<Terms />} />

        {/*************************PROTECTED ROUTES************************************** */}

        {/*************************404************************************** */}

        {/* List All 404 routes here */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default RouterConfig;
