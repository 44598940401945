import React from 'react';
import {
  settings,
  users,
  recording,
  expandCircle,
  chatCircle,
  eclipse,
  chat,
  smileyFace,
  send
} from 'assets/icons';

const LiveSection = () => {
  return (
    <div className="md:px-40 md:pt-20 px-8 pt-12">
      <div className="grid md:grid-cols-12 gap-4 sm:grid-cols-3">
        <div className="md:col-span-8 h-[300px] md:h-[500px] mb-10">
          <div className="md:h-[50px] flex">
            <div className="md:basis-3/6 basis-2/3 pb-2 md:pr-16 pr-2">
              <div className="flex flex-row justify-between pr-2">
                <img src={settings} />
                <button className="bg-[#FEE2E2] text-sm text-main rounded py-1 px-4 border-black">
                  LIVE
                </button>
                <img src={users} />
                <p className="text-main">132 watching</p>
              </div>
            </div>
            <div className="md:pl-20">
              <div className="flex">
                <img src={recording} />
                <p className="text-main inline px-2">Recording</p>
              </div>
            </div>
          </div>
          <div className="bg-[#B3DAC5] md:h-[450px] h-[300px] relative">
            <div className="absolute left-3 top-3">
              <img src={expandCircle} />
            </div>
            <div className="absolute right-3 bottom-3">
              <img src={chatCircle} />
            </div>
            <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <p className="font-normal text-2xl">Tap to join the meeting</p>
            </div>
          </div>
        </div>
        <div className="bg-[#F4F6F7] md:w-[260px] h-[300px] md:h-[500px] relative">
          <div className="absolute top-2 right-2">
            <img src={eclipse} />
          </div>
          <div className="flex justify-between py-6 px-4">
            <p className="text-[#B78628] text-base font-bold	">Live Chat</p>
            <p className="text-main">Q & A</p>
            <p className="text-main">Participants</p>
          </div>
          <div className="absolute top-[50%] px-5 translate-y-[-50%]">
            <div className="flex justify-center">
              <img src={chat} />
            </div>
            <div className="flex justify-center">
              <p className="font-normal text-base">Meeting has not yet started</p>
            </div>
          </div>
          <div className="absolute bottom-2 py-6 px-4">
            <div className="flex justify-between">
              <img src={settings} />
              <div className="relative px-1">
                <img
                  src={smileyFace}
                  className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-2"
                />

                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Send a message"
                  className="form-input placeholder-[#66768E] rounded py-1 pl-10 w-[180px]"
                />
              </div>
              <img src={send} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveSection;
