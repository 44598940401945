import React from 'react';
import NewsView from './NewsView';

export const NewsContainer = () => {
  return (
    <div>
      <NewsView />
    </div>
  );
};
