const data = [
  {
    id: 1,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  },
  {
    id: 2,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  },
  {
    id: 3,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  },
  {
    id: 4,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  },
  {
    id: 5,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  },
  {
    id: 6,
    date: '21 NOV 2022',
    views: '70K',
    readTime: '10 MINS READ',
    content: 'How to Remain Statutory Compliant & Avoid Last Minute Payroll Hassles?',
    newsLink: '/'
  }
];
export default data;
