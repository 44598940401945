import React, { useState, createContext } from 'react';
import Step from './Step';
import Stepper from './Stepper';

export const DonationContext = createContext();

function Context() {
  const { activeStepIndex, setActiveStepIndex } = useState(0);
  const { formData, setFormData } = useState({});
  return (
    <DonationContext.Provider
      value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}
    >
      <Stepper />
      <Step />
    </DonationContext.Provider>
  );
}

export default Context;
