import React, { useState, memo } from 'react';
import data from 'components/data/QuotesCarouselData';
import { arrowBlackRight, arrowBlackLeft } from 'assets/icons';

function QuotesCarousel() {
  const myData = data;
  const dataLength = myData.length;
  const [activeSlide, setActiveSlide] = useState(1);

  const prevSliderHandler = (id) => {
    if (id === 1) {
      setActiveSlide(dataLength);
    } else if (id > 1) {
      setActiveSlide(activeSlide - 1);
    } else {
      setActiveSlide(dataLength - 1);
    }
  };

  const nextSliderHandler = (id) => {
    if (id === dataLength) {
      setActiveSlide(1);
    } else if (id < dataLength) {
      setActiveSlide(activeSlide + 1);
    } else {
      setActiveSlide(dataLength - 1);
    }
  };

  // setInterval(() => {
  //   setActiveSlide((activeSlide + 1) % dataLength);
  //   if (activeSlide === dataLength - 1) {
  //     setActiveSlide(activeSlide - 1);
  //   }
  // }, 6000);

  return (
    <div>
      {myData.map((items) => {
        const { id, content, author } = items;
        return (
          <div key={id} className={activeSlide === id ? 'bg-white' : 'hidden'}>
            <div className="flex flex-col  sm:ml-0 h-52">
              <p className="sm:text-xl text-base text-content font-medium mx-2">{content}</p>
              <p className="sm:text-xl text-base text-content font-bold text-left ">{author}</p>
            </div>

            <div className="flex items-center mt-8 w-full justify-center pb-8">
              <button
                onClick={() => prevSliderHandler(id)}
                className="bg-theme hover:bg-gold hover:text-white text-main py-1 px-2 rounded-l "
              >
                <img src={arrowBlackLeft} />
              </button>
              <button
                onClick={() => nextSliderHandler(id)}
                className="bg-theme hover:bg-gold hover:text-white text-main py-1 px-2 rounded-r"
              >
                <img src={arrowBlackRight} />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default memo(QuotesCarousel);
