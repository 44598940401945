import React from 'react';
import PropTypes from 'prop-types';

function TestimonialCard({ image, name, title, content }) {
  return (
    <div className="bg-gold w-96 h-96">
      <div className="flex pt-8 pb-4 pl-8">
        <div className="">
          <img src={image} alt={title} className="h-16 w-16" />
        </div>
        <div className="h-20 pl-2">
          <h3 className="text-white font-bold">{name}</h3>
          <p className="text-white">{title}</p>
        </div>
      </div>
      <div>
        <p className="text-white px-12 text-center">{content}</p>
      </div>
    </div>
  );
}
TestimonialCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string
};

export default TestimonialCard;
