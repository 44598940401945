import React, { useState, useEffect } from 'react';
import { TestimonialCard } from 'components/modules/cards';
import { MultiCarousel } from 'components/modules/carousels';

import { TestimonialCarouselData } from 'components/data';

function TestimonialSection() {
  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    const data = TestimonialCarouselData;
    setTestimonial([...data]);
  }, []);

  return (
    <div className="bg-main py-12 md:px-40 px-6">
      <div>
        <h2 className="font-bold md:text-5xl text-4xl text-white mb-8 text-center px-2">
          What people are saying about us
        </h2>
      </div>

      <MultiCarousel>
        {testimonial.map((testimonialItem) => {
          return (
            <TestimonialCard
              key={testimonialItem.id}
              image={testimonialItem.image}
              name={testimonialItem.name}
              title={testimonialItem.title}
              content={testimonialItem.content}
            />
          );
        })}
      </MultiCarousel>
    </div>
  );
}

export default TestimonialSection;
