import React from 'react';
import PropTypes from 'prop-types';

const EventsCardContainer = ({ children }) => {
  return (
    <div className="grid md:grid-cols-3 md:gap-28 grid-cols-1 gap-8 my-6 justify-center items-center">
      {children}
    </div>
  );
};

EventsCardContainer.propTypes = {
  children: PropTypes.any
};

export default EventsCardContainer;
