import { PageHeader } from 'components/modules/headers';
import { PageNavBar } from 'components/modules/navbars';
import React from 'react';
import ErrorPageView from './ErrorPageView';

export const ErrorPageContainer = () => {
  return (
    <div>
      <PageHeader />
      <PageNavBar />
      <ErrorPageView />
    </div>
  );
};
