/**
This button is a generic one that can used for links and non links button
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function PrimaryButton(props) {
  const { children } = props;

  if (props.to !== undefined) return <Link {...props}>{children}</Link>;

  if (props.href !== undefined) return <a {...props}>{children}</a>;

  return <button {...props}>{children}</button>;
}

PrimaryButton.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  to: PropTypes.string
};

export default memo(PrimaryButton);
