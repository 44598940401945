import React from 'react';
import { HeroSection, Introduction } from './components';

function PartnerView() {
  return (
    <div>
      <HeroSection />
      <Introduction />
    </div>
  );
}

export default PartnerView;
