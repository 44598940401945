import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { DonationContext } from './Context';
import { arrowLeft, arrowRight } from 'assets/icons';
import { Button } from 'components/widgets/buttons';

function Payment() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(DonationContext);

  const formikInitialValues = {
    saveInformation: false,
    coverCost: false
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };
  return (
    <div>
      <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize={true}>
        <Form>
          <div className="flex flex-col gap-4">
            <div>
              <p className="font-bold text-2xl py-4">Make payment with</p>
              <Button className="bg-[#DBF5E8] rounded-md">Paypal</Button>
              <p className="py-8">or</p>
              <Button className="bg-[#DBF5E8] rounded-md">Stripe</Button>
            </div>
            <div>
              <label className="flex items-center cursor-pointer gap-4">
                <input id="saveInformation" name="saveInformation" type="checkbox" className="" />
                <span className="font-normal text-2xl text-main">
                  Save information for future donations
                </span>
              </label>

              <label className="flex items-center cursor-pointer gap-4">
                <input id="coverCost" name="coverCost" type="checkbox" className="" />
                <span className="font-normal text-2xl text-main">
                  Optionally add $0.07 to cover processing fee
                </span>
              </label>
            </div>
          </div>

          <div className="pt-20 flex gap-4">
            <button
              type="submit"
              onClick={() => setActiveStepIndex(activeStepIndex - 1)}
              className="bg-white text-main border-2 border-main hover:border-gold rounded-md px-4 py-2 w-[30%] hover:bg-gold hover:text-white"
            >
              <img src={arrowLeft} />
              Back
            </button>
            <button
              type="submit"
              className="bg-gold px-8 py-2 text-white rounded-md border-2 border-gold hover:border-main w-[70%] hover:bg-main"
            >
              Donate {formData.amount} {} <img src={arrowRight} className="md:h-6 h-4 md:w-6 w-4" />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default Payment;
