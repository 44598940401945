const PDF_PATH = '/assets/pdf';

export const AHMPROCLAMATION = `${PDF_PATH}/AHMPROCLAMATION.pdf`;
export const AHMSpeech = `${PDF_PATH}/AHMSpeech.pdf`;
export const BiddenHarrisPress = `${PDF_PATH}/BiddenHarrisPress.pdf`;
export const BMHMJusticeForKenion = `${PDF_PATH}/BMHMJusticeForKenion.pdf`;
export const GhanaHQALD58 = `${PDF_PATH}/GhanaHQALD58.pdf`;
export const GHANAHQPAP = `${PDF_PATH}/GHANAHQPAP.pdf`;
export const USAFAzania = `${PDF_PATH}/USAFAzania.pdf`;
export const USAFQUEENELIZABETHDEATH = `${PDF_PATH}/USAFQUEENELIZABETHDEATH.pdf`;
