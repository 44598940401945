import React from 'react';

const AboutInfoSection = () => {
  return (
    <div className=" text-main">
      <h3 className="text-2xl font-normal mb-2">About Session</h3>
      <div className="md:text-2xl text-base">
        <p>
          Join us on Black Mental Health Matters show as we mark World Suicide Prevention Day under
          theme : Creating Hope through Action.
        </p>
        <h3 className="text-2xl font-bold pt-4 pb-2">Statistics:</h3>
        <p className="text-justify">
          The World Health Organization (WHO) estimates more than 700,000 people die due to suicide
          each year and that almost 77% of all global suicides occur in low and middle-income
          countries (LMICs). For every suicide there are many more who attempt suicide or have
          serious suicidal ideation. Suicidal behavior profoundly impacts families and communities
          and remains a universal challenge with millions impacted. The reduction of suicide
          mortality is of global importance and a vital public health consideration.
        </p>
      </div>
    </div>
  );
};
export default AboutInfoSection;
