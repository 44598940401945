import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import RouterConfig from 'navigation/RouterConfig';
import { ScrollToTopButton } from 'components/widgets/buttons';
import { GoToTop } from 'components/GoToTop';

function App() {
  return (
    <div className="App font-poppins">
      <Provider store={store}>
        <BrowserRouter>
          <GoToTop />
          <RouterConfig />
          <ScrollToTopButton />
        </BrowserRouter>
      </Provider>
    </div>
  );
}
export default App;
