import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '.';

function PdfButton(props) {
  const { children } = props;

  return (
    <Button
      {...props}
      className="bg-white hover:bg-gold hover:text-white md:py-3 py-2  md:px-24 px-6 rounded text-gold border-2 border-gold"
    >
      {children}
    </Button>
  );
}

PdfButton.propTypes = {
  children: PropTypes.any
};
export default PdfButton;
