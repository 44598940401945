import React, { useState, useEffect } from 'react';
import { scrollToTop } from 'assets/icons';
import { Link } from 'react-router-dom';
import { HeroSection } from 'components/layout/Home';

function ScrollToTopButton() {
  const [showTopButton, setShowTopButton] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 600) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
  }, []);
  return (
    <div>
      {showTopButton && (
        <Link
          to={HeroSection}
          className=" bg-green fixed z-90 bottom-10 md:right-20 right-8 w-12 h-12 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:drop-shadow-2xl hover:animate-bounce duration-300"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
        >
          <img src={scrollToTop} />
        </Link>
      )}
    </div>
  );
}

export default ScrollToTopButton;
