import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

function LongDarkButton(props) {
  const { children } = props;

  return (
    <Button
      {...props}
      className="bg-black hover:bg-gold text-white py-3 px-6 rounded w-full sm:w-11/12 text-center"
    >
      {children}
    </Button>
  );
}

LongDarkButton.propTypes = {
  children: PropTypes.any
};
export default LongDarkButton;
