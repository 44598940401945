import React from 'react';
import { HrConsultingHeroSection, HrConsultingSection } from 'components/layout/Requirements';
import ServicesDonateCard from 'components/modules/cards/ServicesDonateCard';

function RequirementsView() {
  return (
    <HrConsultingSection>
      <HrConsultingHeroSection />
      <ServicesDonateCard />
    </HrConsultingSection>
  );
}

export default RequirementsView;
