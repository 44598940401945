import React from 'react';

const RacialJustice = () => {
  return (
    <div className="my-4">
      <h5 className="py-2 text-3xl font-semibold sm:font-bold ">Racial Justice</h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        Daniel has been in the frontline to advocate for{' '}
        <span className="text-gold">racial justice</span> and has been using social media to
        advocate for equal rights for African Americans and an end to{' '}
        <span className="text-gold">police brutality</span>
        against black people worldwide.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        He is a <span className="text-gold">flaming sword against neo-colonialists</span>, an{' '}
        <span className="text-gold">anti-imperialist</span> who has become an inspiring symbol of
        national resistance against colonial domination and exploitation of Africa.
      </p>
    </div>
  );
};

export default RacialJustice;
