import React from 'react';
import { QuotesCarousel } from 'components/modules/carousels';
import MapSection from './MapSection';

function QuotesSection() {
  return (
    <div className="md:flex md:flex-row w-full items-center md:px-40 px-8 py-12">
      <MapSection />
      <div className="md:w-1/2 text-center md:text-left px-1 pt-8">
        <h2 className="md:text-4xl text-3xl text-black md:text-left font-extrabold pb-4 w-11/12 text-center md:full px-auto">
          Inspirational Quotes and Poems
        </h2>
        <QuotesCarousel />
      </div>
    </div>
  );
}

export default QuotesSection;
