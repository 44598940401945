import React from 'react';
import NavBarLinks from './NavBarLinks';

function PageNavBar() {
  return (
    <div>
      <NavBarLinks />
    </div>
  );
}

export default PageNavBar;
