import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import ConnectView from './ConnectView';

export const ConnectContainer = () => {
  return (
    <PageWrapper>
      <ConnectView />
    </PageWrapper>
  );
};
