import { PageWrapper } from 'components/modules/wrapper';
import React from 'react';
import OrganizationView from './OrganizationView';

export const OrganizationContainer = () => {
  return (
    <PageWrapper>
      <OrganizationView />
    </PageWrapper>
  );
};
