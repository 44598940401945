import React from 'react';

const Technology = () => {
  return (
    <div className="my-12">
      <h5 className="py-2 text-3xl font-semibold  sm:font-bold ">
        Technology and Artificial Intelligence
      </h5>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        Daniel Mwambonu is an undisputed defender of the right of Africa to be{' '}
        <span className="text-gold">sovereign and a staunch Ambassador for Pan Africanism</span>, he
        firmly believes that in order for the continent to undergo the{' '}
        <span className="text-gold">African renaissance</span>, Africans must get out of the boxes
        colonial masters caged us and begin to exercise full control over our internal and foreign
        affairs.
      </p>
      <p className="text-base font-normal sm:text-2xl text-justify py-3">
        African states fearlessly <span className="text-gold">fought against colonialism</span> so
        that the 21st century can enjoy the freedom to determine their own destiny but this
        sometimes has been overridden by
        <span className="text-gold">neocolonialist incursions and imperialist domination</span> of
        our affairs.
      </p>
    </div>
  );
};

export default Technology;
