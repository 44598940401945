import React from 'react';

import EventsView from './EventsView';

export const EventsContainer = () => {
  return (
    <div>
      <EventsView />
    </div>
  );
};
