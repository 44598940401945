import React, { useContext } from 'react';
import { envelope, person } from 'assets/icons';
import { Form, Formik } from 'formik';
import { InputFormGroup } from 'components/modules/forms';
import { DonationContext } from './Context';

function Information() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(DonationContext);

  const formikInitialValues = {
    fullName: '',
    email: ''
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };
  return (
    <Formik initialValues={formikInitialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      <Form className="shadown-md">
        <div className="flex items-end my-4">
          <InputFormGroup
            type="text"
            nameAndId="fullName"
            label="Full name"
            placeholder="Jane Doe."
            iconUrl={person}
          />
        </div>
        <div className="flex  my-4">
          <InputFormGroup
            type="email"
            nameAndId="email"
            label="Email"
            placeholder="janedoe@email.com"
            iconUrl={envelope}
          />
        </div>
        <div className=" flex mt-20">
          <button
            onClick={() => {
              setActiveStepIndex(activeStepIndex - 1);
            }}
            className="w-full bg-white rounded-md text-main border-main hover:bg-main hover:border-main hover:text-white py-2"
          >
            Back
          </button>
          <button
            type="submit"
            className="w-full bg-gold rounded-md text-white border-gold hover:bg-main hover:border-main py-2"
          >
            Next
          </button>
        </div>
      </Form>
    </Formik>
  );
}

export default Information;
