import React from 'react';
import PropTypes from 'prop-types';
import { Arrow } from 'components/widgets/icons';

function DropdownButton(props) {
  const { className, isopen } = props;
  const newProps = {
    ...props,
    className: `inline-flex sm:justify-content justify-center items-center w-full ${
      className || ''
    }`
  };
  return (
    <span className="flex justify-center">
      <button
        {...newProps}
        className="flex items-center hover:text-gold justify-center mt-12 sm:my-6 md:my-2 group "
      >
        {props.children}
        <span className="relative group-hover:top-[2px] transition-all ml-2 group-hover:rotate-180">
          {isopen ? <Arrow direction="bottom" /> : <Arrow direction="top" />}
        </span>
      </button>
    </span>
  );
}

DropdownButton.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  isopen: PropTypes.bool
};

export default DropdownButton;
