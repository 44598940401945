import React, { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { amountSchema } from 'validations';
import AmountButton from './AmountButton';
import AmountInput from './AmountInput';
import { DonationContext } from './Context';
import PaymentOptionButton from './PaymentOptionButton';
import { arrowRight } from 'assets/icons';
import { Button } from 'components/widgets/buttons';

function ChooseAmount() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(DonationContext);

  const [openTextArea, setOpenTextArea] = useState(false);
  const [amount, setAmount] = useState('');

  const formikInitialValues = {
    donationType: 'one-time',
    amount: '',
    writeComment: false,
    comment: ''
  };

  const handleSubmit = (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
  };

  const handleCheckClick = () => {
    setOpenTextArea(!openTextArea);
  };
  return (
    <Formik
      initialValues={formikInitialValues}
      validate={amountSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form className="md:px-8 sm:px-8 px-2 md:h-[800px] h-[450px] shadow-md md:-mt-12">
        <div className=" h-20 md:mt-12 mt-2 flex justify-center items-center">
          <PaymentOptionButton
            onClick={() => setFormData({ ...formData, donationType: 'one-time' })}
          >
            One-Time
          </PaymentOptionButton>
          <PaymentOptionButton
            onClick={() => setFormData({ ...formData, donationType: 'monthly' })}
          >
            Monthly
          </PaymentOptionButton>
          <PaymentOptionButton
            onClick={() => setFormData({ ...formData, donationType: 'quarterly' })}
          >
            Quarterly
          </PaymentOptionButton>
          <PaymentOptionButton
            onClick={() => setFormData({ ...formData, donationType: 'annualy' })}
          >
            Annualy
          </PaymentOptionButton>
        </div>

        <div className="flex sm:gap-4 md:gap-8 gap-2 md:mt-12 justify-center">
          <div className="flex py-4 md:gap-6 gap-3">
            <AmountButton onClick={() => setAmount(100)}>
              <span className="text-xl md:pr-2 pr-1">$</span>100
            </AmountButton>
            <AmountButton onClick={() => setAmount(200)}>
              <span className="text-xl md:pr-2 pr-1">$</span>200
            </AmountButton>
            <AmountButton onClick={() => setAmount(500)}>
              <span className="text-xl md:pr-2 pr-1">$</span>500
            </AmountButton>
          </div>
        </div>

        <div className="md:mt-12 mt-2 text-center">
          <AmountInput
            nameAndId="amount"
            type="number"
            value={amount}
            placeholder="$ Custom Amount"
            className=""
          />
        </div>

        <div className="md:mt-12 mt-4 flex gap-4 items-center justify-start">
          <input
            type="checkbox"
            name="writeComment"
            className="accent-gold"
            onClick={handleCheckClick}
          />
          <label htmlFor="comment" className="font-normal md:text-2xl text-xs text-main">
            Write us a comment
          </label>
        </div>

        <div className={` md:pt-8 pt-4 ${openTextArea ? 'block' : 'hidden'}`}>
          <textarea
            name="comment"
            className="bg-theme border-theme rounded-md grow md:max-h-30vh max-h-12  w-full"
          ></textarea>
        </div>

        <Button
          type="submit"
          className="bg-gold hover:bg-main md:py-4 py-2 px-8 w-full flex md:gap-4 gap-2 text-white items-center justify-center rounded-md md:mt-12 mt-8 md:text-lg text-base md:font-semibold font-normal"
        >
          Next <img src={arrowRight} className="md:w-6 w-4 md:h-6 h-4" />
        </Button>
        <p className="text-gold underline underline-offset-4 font-normal md:text-xl text-xs text-center md:mt-44 mt-12">
          Powered by Daniel
        </p>
      </Form>
    </Formik>
  );
}

export default ChooseAmount;
