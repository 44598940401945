import React from 'react';
import HomeView from './HomeView';

export const HomeContainer = () => {
  return (
    <div className="bg-white">
      <HomeView />
    </div>
  );
};
