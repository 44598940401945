function useTailwindSquareSizing() {
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'];
  const get = (size) => {
    switch (size) {
      case 'xs':
        return 'h-2 w-2';

      case 'md':
        return 'h-3 w-3';

      case 'lg':
        return 'h-4 w-4';

      case 'xl':
        return 'h-5 w-5';

      case '2xl':
        return 'h-7 w-7';

      case '3xl':
        return 'h-10 w-10';

      case 'sm':
      default:
        return 'h-2.5 w-2.5';
    }
  };

  return { sizes, getSize: get };
}

export default useTailwindSquareSizing;
