import React, { useContext } from 'react';
import { ConnectContext } from './Context';
import ConnectForm from './ConnectForm';
import Success from './Success';

function Step() {
  const { activeStepIndex } = useContext(ConnectContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <ConnectForm />;
      break;
    case 1:
      stepContent = <Success />;
      break;
    default:
      break;
  }
  return stepContent;
}

export default Step;
