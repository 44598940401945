export const data = [
  {
    id: 1,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 2,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 3,
    views: '70K',
    title: 'How to do main text!',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 4,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 5,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 6,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 7,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 8,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  },
  {
    id: 9,
    views: '70K',
    description: 'World Suicide Prevention Day Creating Hope through Action',
    date: '21  Nov 2022',
    viewers: '132 Watching'
  }
];
export default data;
