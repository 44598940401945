import React from 'react';

const TermsSection = () => {
  return (
    <div className="md:px-40 px-8 text-main flex flex-col md:gap-16 gap-8 md:py-12 py-8">
      <p className="text-3xl md:text-5xl font-bold">Terms & Conditions</p>
      <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
        Welcome to the &quot;Daniel Mwabonu&quot; website (the &quot;Site&quot;). This Site is owned
        and operated by the non-profit organization USAF of which Daniel Mwabonu is the president.
        These terms and conditions (&quot;Terms&quot;) govern your access to and use of the Site, so
        please read them carefully. By accessing or using the Site, you acknowledge that you have
        read, understand, and agree to be bound by these Terms. If you do not agree to these Terms,
        you may not access or use the Site.
      </p>

      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Content on the Site</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          The content on the Site, including text, graphics, images, and other material (the
          &quot;Content&quot;), is for informational purposes only. The Content is not intended to
          be a substitute for professional advice, diagnosis, or treatment. Always seek the advice
          of your qualified healthcare provider with any questions you may have regarding a medical
          condition.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Intellectual Property</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          The Site and its Content are protected by copyright and trademark laws. You may not use
          any Content on the Site for commercial purposes without the express written consent of the
          Organization..
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-3xl font-bold">Third-Party Links</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          The Site may contain links to third-party websites. These links are provided for your
          convenience only, and the Organization is not responsible for the content or privacy
          practices of these websites.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Disclaimer of Waranties</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          The Site and its Content are provided on an &quot;as is&quot; and &quot;as available&quot;
          basis. The Organization makes no representations or warranties of any kind, express or
          implied, as to the operation of the Site or the information, content, materials, or
          products included on the Site. The Organization will not be liable for any damages of any
          kind arising from the use of the Site, including, but not limited to, direct, indirect,
          incidental, punitive, and consequential damages.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Governing Law</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          These Terms and your use of the Site will be governed by and construed in accordance with
          the laws of the state in which the Organization is headquartered, without giving effect to
          any principles of conflicts of law.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Changes to the Terms</p>
        <p className="text-base md:text-2xl font-normal text-justify leading-8 md:leading-10">
          The Organization reserves the right to update these Terms at any time. Any changes to
          these Terms will be effective immediately upon posting on the Site. Your continued use of
          the Site after any changes are posted will constitute your acceptance of the revised
          Terms.
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-2xl md:text-3xl font-bold">Contact Us</p>
        <p className="text-base md:text-2xl font-normal  leading-8 md:leading-10">
          If you have any questions about these Terms or the Site, please contact us at
          <span className="text-gold">
            <a href="mailto:info@danielmwambonu.com"> contact@danielmwambonu.com</a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default TermsSection;
