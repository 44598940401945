export const HOME = '/';
export const BIOGRAPHY = '/biography';

export const MEDIA = {
  PRESS: '/media/press',
  VIDEOS: '/media/videos',
  NEWS: '/media/news',
  ORGANIZATION: '/media/organization'
};

export const SERVICES = {
  PARTNER: '/services/partner',
  SERVICES: '/services/services',
  REQUIREMENTS: '/services/requirements'
};

export const EVENTS = {
  LIVE: '/events/live',
  PAST: '/events/past',
  UPCOMING: '/events/upcoming'
};

export const CONNECT = '/connect';
export const DONATION = '/donation';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
