import React from 'react';
import PropTypes from 'prop-types';

function OrganizationCard({ image, title, content, btnUrl }) {
  return (
    <div className="items-center justify-center">
      <div className="h-[403px] md:w-[586px] w-full bg-[#DBF5E8] sm:px-12 px-4 md:py-6 py-3 flex flex-col items-center justify-center rounded-md shadow-md">
        <div className="bg-white rounded-full h-24 w-24 flex items-center justify-center">
          <img src={image} className="h-24 w-24" />
        </div>
        <h1 className="text-gold md:text-3xl text-2xl font-semibold h-16 flex items-center text-center">
          {title}
        </h1>
        <p className="text-main font-medium md:text-xl text-lg text-center md:h-32 h-36">
          {content}
        </p>
        <a
          href={btnUrl}
          target="_blank"
          className="text-gold border-2 border-gold bg-[#DBF5E8] w-full p-3 rounded-md hover:bg-gold hover:text-white text-center"
          rel="noreferrer"
        >
          Visit Website
        </a>
      </div>
    </div>
  );
}
OrganizationCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  btnUrl: PropTypes.string
};

export default OrganizationCard;
