import React, { useState, useEffect } from 'react';
import { PressCard, PressCardContainer } from 'components/modules/cards';
import { PressPaginateData } from 'components/data';
import ReactPaginate from 'react-paginate';

const postsPerPage = 2;

function CardSection() {
  const [currentPage, setCurrentPage] = useState(0);
  const [pressData, setPressData] = useState([]);

  useEffect(() => {
    const data = PressPaginateData;
    setPressData([...data]);
  }, []);

  function handlePageClick({ selected: selectedPage }) {
    console.log('selectedPage', selectedPage);
    setCurrentPage(selectedPage);
  }

  const offset = currentPage * postsPerPage;

  const pressCard = pressData.slice(offset, offset + postsPerPage).map((item) => {
    return (
      <PressCard
        key={item.id}
        imageURL={item.imageURL}
        title={item.title}
        content={item.content}
        // pdfUrl={item.pdfUrl}
      />
    );
  });

  const pageCount = Math.ceil(pressData.length / postsPerPage);

  return (
    <div className="md:px-40 px-8 pt-12 pb-28">
      <h2 className="text-main md:my-12 mb-4 text-4xl md:text-6xl font-semibold md:text-left">
        Press Releases PDF’s
      </h2>

      <PressCardContainer>
        {pressCard}

        <div className="col-span-full flex justify-center my-12">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            pageCount={pageCount}
            marginPagesDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'btn-group flex-nowrap gap-2 no-scrollbar justify-center'}
            pageClassName={
              'btn btn-ghost btn-square btn-sm border-gold bg-theme hover:bg-gold hover:text-white rounded-md'
            }
            pageLinkClassName={
              'w-full h-full flex gap-4 items-center justify-center border-gold bg-white hover:bg-gold hover:text-white'
            }
            previousClassName={
              'btn btn-ghost btn-square btn-sm bg-theme border-theme hover:bg-gold hover:border-gold hover:text-white rounded-md'
            }
            previousLinkClassName={
              'w-full h-full flex gap-4 items-center justify-center rounded-md'
            }
            nextClassName={
              'btn btn-ghost btn-square btn-sm border-gold hover:text-white bg-gold text-white rounded-md'
            }
            nextLinkClassName={'w-full h-full flex gap-4 items-center justify-center rounded-md'}
            breakClassName={'btn btn-ghost btn-square btn-sm border-gold'}
            breakLinkClassName={'w-full h-full flex gap-4 items-center justify-center rounded-md'}
            activeClassName={'bg-gold'}
          />
        </div>
      </PressCardContainer>
    </div>
  );
}

export default CardSection;
